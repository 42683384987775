import React, { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useParams, useLocation } from "react-router-dom";
import { message } from "antd";

export const useFlatDetailsElevator = () => {
  const {state} = useLocation();
  const { authAxios, authAxios2 } = useContext(FetchContext);
  const [users, setUsers] = useState([]);
  const [payments, setPayments] = useState([]);
  const [urlParams, setUrlParams] = useState(useParams);
  const [deviceId, setDeviceId] = useState(urlParams.id);
  const [id, setId] = useState(urlParams.number);
  const [flatNumber, setFlatNumber] = useState("");
  const [flatDetails, setFlatDetails] = useState<any[]>([state['flat']]);
  const [refresh, setRefresh] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [balance, setBalance] = useState("");
  const [elevatorCode, setElevatorCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [addNFCModal, setAddNFCModal] = useState<boolean>(false);
  const [elevatorId, setElevatorId] = useState(useParams);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showNFCConfirm, setShowNFCConfirm] = useState(false);
  const [NFCs, setNFCs] = useState([]);
  const [processingNFC, setProcessingNFC] = useState([])

  const getFlatDetails = async () => {
    try {
        const flatsData = await authAxios2.get(`/admin/flats/elevators/${elevatorId.id}`);
        if(flatsData) {
            let details = flatsData.data.elevator.flats.filter((item) => {
                return item.flat_number === Number(elevatorId.number)
            });
            setFlatDetails(details);

            let users = flatsData.data.elevator.flatsUser.filter((item) => {          
              return item.flat_id === details[0].id
            });
            getUser(users);
            setUsers(users);
        }
    } catch (e) {
        //console.log('GET FLATS ERROR ==>', e);
    }
  };


  const getUser = async (users) => {
    let newArray = [...users];
    for(let user of newArray) {
      if(user.user_id) {
        try {
          const response = await authAxios2.get(`/admin/user/${user.user_id}`);
          if(response) {
            //console.log('GET USER RESPONSE ==>', response);
            user['name'] = response.data.user.name;
            user['phoneNumber'] = response.data.user.phone_number;
            user['email'] = response.data.user.email;
            user['isAdmin'] = false;
          }
        } catch (e) {
          console.log('GET USER ERROR ==>', e);
        }
      }
    }
    setUsers(newArray);
  };


  const getNFCDetails = async () => {
      const data = await authAxios2.get(`/admin/nfc/flat/${flatDetails[0].id}`);
      setNFCs(data.data.nfcCodes);
  };

    const refreshNFCDetails = async (status: boolean, dataMessage: string, id: string) => {
        const data = await authAxios2.get(`/admin/nfc/flat/${flatDetails[0].id}`);
        setNFCs(data.data.nfcCodes);

        const nfcCode = data.data.nfcCodes.find(item => item.id == id);
        if (nfcCode && nfcCode.nfc_code_enabled == status) {
            message.success(
                dataMessage != null
                    ? dataMessage
                    : "NFC has been successfully enabled"
            );
        } else {
            message.error("NFC could not be enabled!");
        }

        const updatedList = processingNFC.filter(item => item !== id);
        setProcessingNFC(updatedList);
    };

  useEffect(() => {
    getFlatDetails();
    getNFCDetails();
  }, [authAxios2, refresh]);

  const handleFlatActivationOnClick = (active: boolean) => {
      let urlToggle = active ? "disable" : "enable";
      const updateActive = async () => {
      await authAxios2
          .put(`admin/flat/nfc/${urlToggle}/${flatDetails[0].id}`)
          .then(async (response) => {
              if (response) {
                  updateFlatActivation(active);
              }
          })
          .catch((err) => {
              message.error(
                  "There was a problem flat update. Please try again later!"
              );
          });
    };
    updateActive();
  };

  const updateFlatActivation = (active: boolean) => {
      const updateActive = async () => {
          await authAxios2
              .put(`/admin/flat/active/toggle`, {
                  is_active: !active,
                  flat_id: flatDetails[0].id,
                  elevator_id: elevatorId.id
              })
              .then(async (response) => {
                  if (response) {
                      message.success("Flat has been successfully updated");
                      getFlatDetails();
                      getNFCDetails();
                  }
              })
              .catch((err) => {
                  message.error(
                      "There was a problem flat update. Please try again later!"
                  );
              });
      }
      updateActive();
  }

  const addUser = async () => {
    await authAxios2
      .post(`/admin/elevators/link/phone`, {
        code: elevatorCode,
        phone: phoneNumber,
      })
      .then((response) => {
        if (response) {
          message.success("User has successfully added");
          getFlatDetails();
          setShowAddUserModal(false);
        }
      })
      .catch((err) => {
        message.error("There was a problem add user.");
        setShowAddUserModal(false);
      });
  };

  const editBalance = async () => {
    await authAxios
      .put(`admin/flats/${id}`, {
        flatBalance: +balance,
      })
      .then((response) => {
        if (response) {
          message.success("Balance has been successfully edited!");
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while editing balance!");
      });
  };

  const handleUserActivationOnClick = (active: any, userId: any) => {
    if (active == null) {
      message.error(
        "User details cannot be edited if the user has not signed up yet"
      );
    } else {
      const updateActive = async () => {
        await authAxios2
          .put(`/admin/user/active/toggle`, {
            is_active: !active,
            flat_id: Number(elevatorId.number),
            elevator_id: Number(elevatorId.id),
            user_id: userId
          })
          .then((response) => {
            if (response) {
              message.success("User has been successfully updated");
              getFlatDetails();
            }
          })
          .catch((err) => {
            message.error(
              "There was a problem with user update. Please try again later!"
            );
          });
      };
      updateActive();
    }
  };

  const handleEditBalanceModal = () => {
    setShowEditModal(true);
  };

  const handleAddUserModal = (code) => {
    setShowAddUserModal(true);
    setElevatorCode(code);
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
  };

  const handleCancelAddUser = () => {
    setShowAddUserModal(false);
  };

  const handleDeleteUserFromFlat = async (user: any) => {
    try {
      const response = await authAxios2.post(`/admin/elevator/unlink`, {
        elevator_id: Number(elevatorId.id),
        flat_id: Number(flatDetails[0].id),
        user_id: user
      });
      if(response) {
        getFlatDetails();
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "User has been successfully deleted!"
        );
      }
    } catch (e) {
      console.log('DELETE USER ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "User could not be deleted!"
      );
    }
  };

  const showEditPackageModal = (value: any) => {};
  const handleCancelDelete = () => {
    setDeleteUser(false);
  };

  const handleAddNFCForFlatOnClick = () => {
    setAddNFCModal(true);
  };

  const handleAddNFCModalCancel = () => {
    setAddNFCModal(false);
  };

  const handleAddNFCForFlat = async (data: any) => {
    try {
        const response = await authAxios2.post(`/admin/elevators/nfc/create`, {
            add_manually: true,
            nfc_codes: [
                {
                    flat_id: flatDetails[0].id,
                    elevator_id: elevatorId.id,
                    nfc_code: data.nfc_code,
                    nfc_code_enabled: true
                }
            ]
        });
        if(response) {
            setAddNFCModal(false);
            getNFCDetails();
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "NFC has been successfully added!"
            );
        }
    } catch (e) {
        //console.log('ADD NFC for FLAT ERROR ==>', e);
        setAddNFCModal(false);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be added!"
          );
    }
  };

  const handleNFCDelete = async () => {
    try {
        const response = await authAxios2.delete(`/admin/nfc/delete/${elevatorId.id}/${flatDetails[0].id}`);
        if(response) {
            getNFCDetails();
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "NFC has been successfully deleted!"
            );

        }
    } catch (e) {
        //console.log('DELETE NFC ERROR ==>', e);
         message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be deleted!"
          );
    }
  };

  const handleNFCDeleteCancel = () => {
    setShowConfirm(false);
  };

  const enableNFCforFlat = async (status: boolean, id: string) => {
    if (processingNFC.includes(id)) {
        return;
    }

    setProcessingNFC([...processingNFC, id]);
    try {
        const response = await authAxios2.put(`/admin/nfc/toggle/on/${id}`);
        if(response) {
            refreshNFCDetails(status, response?.data?.message, id);
        }
    } catch (e) {
        //console.log('ENABLE NFC ERROR ==>', e);
         message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be enabled!"
          );

        const updatedList = processingNFC.filter(item => item !== id);
        setProcessingNFC(updatedList);
    }
  };

  const disableNFCforFlat = async (status: boolean, id: string) => {
    if (processingNFC.includes(id)) {
        return;
    }

    setProcessingNFC([...processingNFC, id]);

    try {
        const response = await authAxios2.put(`/admin/nfc/toggle/off/${id}`);
        if(response) {
            refreshNFCDetails(status, response?.data?.message, id);
        }
    } catch (e) {
        //console.log('DISABLE NFC ERROR ==>', e);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be disabled!"
          );

        const updatedList = processingNFC.filter(item => item !== id);
        setProcessingNFC(updatedList);
    }
  };

  const handleNFCActivationOnClick = (data: any) => {
    const status: boolean = !data.nfc_code_enabled;
    if(data.nfc_code_enabled) {
        disableNFCforFlat(status, data.id);
    } else {
        enableNFCforFlat(status, data.id);
    }
  };

  const handleDeleteNFCCode = async (id: any) => {
    try {
      const response = await authAxios2.delete(`/admin/nfc/${id}`);
      if(response) {
        getNFCDetails();
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "NFC Code has been successfully deleted"
        );
      }
    } catch (e) {
      //console.log('DELETE NFC CODE ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "NFC Code could not be deleted!"
      );

    }
  };

  const handleCancelNFCCodeDelete = () => {
    setShowNFCConfirm(false);
  };


  return {
    deviceId,
    flatNumber,
    flatDetails: flatDetails,
    users,
    payments,
    handleFlatActivationOnClick,
    showEditPackageModal,
    handleUserActivationOnClick,
    handleDeleteUserFromFlat,
    handleCancelDelete,
    deleteUser,
    showEditModal,
    handleEditBalanceModal,
    handleCancelEdit,
    setBalance,
    editBalance,
    setPhoneNumber,
    setElevatorCode,
    addUser,
    handleAddUserModal,
    showAddUserModal,
    handleCancelAddUser,
    elevatorCode,
    handleAddNFCForFlatOnClick,
    addNFCModal,
    handleAddNFCModalCancel,
    handleAddNFCForFlat,
    handleNFCDelete,
    handleNFCDeleteCancel,
    NFCs,
    handleNFCActivationOnClick,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete
  };
};
