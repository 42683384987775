import { Button, Input } from "antd";
import React, { FormEvent, useState, FC, useEffect, useContext } from "react";
import styled from "styled-components";
import { ClearOutlined } from "@ant-design/icons";
import { FetchContext } from "../context/FetchContext";

const { Search } = Input;

export interface IPlainObject {
  [key: string]: string;
}

interface IProps {
  setFilterValue: (value: string) => void;
  setIntercomsData;
  getIntercomsForConnect;
  placeHolder: string;
}

const SearchStyled = styled(Search)`
  // width: 20%;
  margin: 10px 0;
`;
const DivStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
`;

export const SearchInput: FC<IProps> = ({
  setFilterValue,
  setIntercomsData,
  getIntercomsForConnect,
  placeHolder,
}) => {
  const [value, setValue] = useState("");
  const { authAxios } = useContext(FetchContext);

  const getAddress = async () => {
    try {
        const response = await authAxios.post(`/admin/intercoms/search`, {
            search: value
        });
        if(response) {
            setIntercomsData(response.data.data);           
        }
    } catch (e) {
        console.log('Get Address ERROR ==>', e);     
    }
  };

  useEffect(() => {
    setFilterValue(value);
    if(value.length >= 3) {
        getAddress();
    }
  }, [value]);

  const onClear = () => {
    setValue("");
  };

  return (
    <DivStyled>
      <Button icon={<ClearOutlined />} onClick={onClear} />
      <SearchStyled
        placeholder={placeHolder}
        value={value}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          const currValue = e.currentTarget.value;
          setValue(currValue);
          if(currValue === "") {
            getIntercomsForConnect();
          }
        }}
      />
    </DivStyled>
  );
};
