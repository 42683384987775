import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import { AuthContext } from "../../../context/AuthContext";
import {useTranslation} from "react-i18next";

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const {t} = useTranslation()
  return (
    <Modal
      open={open}
      title={t("CreateNewService")}
      okText={t("Create")}
      cancelText={t("Cancel")}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
            onCancel();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="name"
          label={t("ServiceName")}
          rules={[
            { required: true, message: "Please input the name of service!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="price" label={t("ServicePrice")}>
          <Input type="number" />
        </Form.Item>
        <Form.Item name="unitName" label={t("Unit")}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="priceType"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group>
            <Radio value="fixed">{t("Fixed")}</Radio>
            <Radio value="flexible">{t("Flexible")}</Radio>
            <Radio value="flexible_days">{t("FlexibleDays")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const NewServiceModal = ({ createService }: any) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation()
  return (
    <div>
      <Button
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
          style={{ marginBottom: 16 }}
        >
          {t("CreateNewService")}
        </Button>
      <CollectionCreateForm
        open={open}
        onCreate={createService}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};
