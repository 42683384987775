import React, { useContext, useEffect, useState } from "react";
import { publicFetch } from "../../util/fetch";
import { AuthContext } from "../../context/AuthContext";
import { message } from "antd";

export const useLoginPage = () => {
  const [verify, setIsVerified] = useState(false);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);
  const { setAuthState } = useContext(AuthContext);

  const setJWT = (data: any) => {
    setAuthState({ token: data.data.token, userInfo: data.data.adminUser });
    localStorage.setItem("userInfo", JSON.stringify(data.data.adminUser)); // set user info in local storage as string
    setRedirectOnLogin(true);
  };

  useEffect(() => {
    const jwt = localStorage.getItem("token");
    if (jwt) {
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        setAuthState({ token: jwt, userInfo: JSON.parse(userInfo) }); // set JWT and user data in AuthContext
      }
      setRedirectOnLogin(true);
    }
  }, []);

  const onFinish = async (values: any) => {
    const data = !verify
      ? await publicFetch
          .post(`/admin/user/login`, values)
          .then((response) => {
            if (
              response.data.data.adminUser.id ===
              "35da8d51-4e35-4b21-ad0f-b251c362a981"
            ) {
              setIsVerified(true);
              setJWT(response.data);
            } else {
              setIsVerified(true);
              setJWT(response.data);
            }
          })
          .catch((error) => {
            message.error("Username Password combination is wrong!");
          })
      : await publicFetch
          .post(`/admin/user/login`, values)
          .then((response) => {
            if (response.data) {
              setIsVerified(true);
              setJWT(response.data);
            }
          })
          .catch((error) => {
            message.error("Username Password combination is wrong!");
          });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return {
    onFinish,
    onFinishFailed,
    verify,
    redirectOnLogin,
  };
};
