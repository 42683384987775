import React, { useContext } from "react";
import logo from "../images/boon_logo.png";
import { Layout, Menu } from "antd";
import { navItems, malkhasyantsNavItems, navItemsLimited } from "./useAppShell";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

const Sidebar = ({ collapsed, onCollapse, defaultSelected }) => {
  const auth = useContext(AuthContext);
  const navItem = auth.isSuperAdmin ? navItems : (auth.authState.userInfo.id === "53714479-21b0-47c0-9b7d-101001e94ac9" || auth.authState.userInfo.id === "a3361db0-3b59-4020-8192-ba3c2052917a" || auth.authState.userInfo.id === "ffca3360-b8d4-4d88-bb97-948d839293e4" || auth.authState.userInfo.id === "09a71f42-33a6-4326-a91a-6f495e3d8b97" || auth.authState.userInfo.id === "1a9d1089-c9dc-434f-abad-b0f0a2295e5b") ? navItemsLimited : malkhasyantsNavItems;
  const { t } = useTranslation()
  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <Menu theme="dark" selectedKeys={[defaultSelected]} mode="inline">
        {navItem.map(({ path, label, icon }, index) => {
          const Icon = icon;
          return (
            <Menu.Item key={index} icon={<Icon />}>
              <Link to={path}>{t(`${label}`)}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
