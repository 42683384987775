import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Modal,
  Table,
} from "antd";
import {
  ExclamationCircleOutlined,
  BulbTwoTone
} from "@ant-design/icons";
import { useHistory } from "./useHistory";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import type { ColumnsType } from 'antd/es/table';
import { TableSearchInput } from "../../components/TableSearchInput";

export const History = () => {
  const {
    keys,
    checkedIDs,
    changeCheckedValue,
    isLoading,
    showFlats,
    handleAddKeysOnClick,
    handleAddKeysCancel,
    assignKeys,
    flats,
    selectedFlat,
    changeCheckedFlats,
    setFilteredValue,
    filteredValue,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete,
    areAllChecked,
    changeSelectAll,
    handleChange,
    currentPage,
    pageSize,
    totalPages
  } = useHistory();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { confirm } = Modal;

  const handleNFCShowConfirm = () => {
    confirm({
      title: t("DeleteKeys"),
      icon: <ExclamationCircleOutlined />,
      content: t("DeleteKeysMessage"),
      onOk() {
        handleDeleteNFCCode();
      },
      onCancel() {
        handleCancelNFCCodeDelete();
      },
    });
  };

  interface DataType {
    key: React.Key;
    id: string;
    code: string;
    createdAt: any;
  }

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => changeCheckedValue(row, id)}
          />
        );
      },
    },
    {
      title: t("Keys"),
      dataIndex: "code",
      key: "id",
    },
    {
      title: t("Status"),
      dataIndex: "event",
      key: "event",
      width: '40px',
      // defaultSortOrder: 'ascend',
      render: (event) => (
        <BulbTwoTone
          twoToneColor={event === 'valid' ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
      // sorter: (a: any, b: any) => {
      //   let value1: any = a.event === "valid" ? true : false;
      //   let value2: any = b.event === "valid" ? true : false;
      //   return value1 - value2;
      // },
    },
    {
      title: t("CreatedDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend"],
      render: (date) => {
        return <div>{format(new Date(date), "dd-MMM-yy H:m:s")}</div>;
      },
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    }
  ];

  const flatColumns = [
    {
      dataIndex: "id",
      key: "checkbox",
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={selectedFlat === id}
            onClick={() => {
              changeCheckedFlats(row, id);
            }}
          />
        );
      },
    },
    {
      title: t("FlatNumber"),
      dataIndex: "flatNumber",
      key: "flatNumber",
    },
  ];


  return (
    <div>
      <div style={{margin: "1em auto", fontSize: "medium", fontWeight: 500}}>
        <span>{keys[0]?.address}</span>
      </div>
      <Button
        onClick={handleAddKeysOnClick}
        type="primary"
        style={{ marginRight: 10 }}
        disabled={!checkedIDs.length}
      >
        {"  "}
        {t("AssignKeys")}
      </Button>
      <Button
        onClick={handleNFCShowConfirm}
        type="primary"
        style={{ marginRight: 10 }}
        disabled={!checkedIDs.length}
      >
        {"  "}
        {t("DeleteKeys")}
      </Button>
      <Divider />
      <TableSearchInput
        setFilterValue={setFilteredValue}
        placeHolder={t("SearchForKey")}
      />
      <Table
        columns={columns}
        dataSource={keys.filter((el) =>
          el?.code
            ?.toLowerCase()
            .includes(filteredValue.toLowerCase())
        )}
        loading={isLoading}
        onChange={handleChange}
        pagination={{
          current: currentPage,
          total: totalPages * pageSize,
          pageSize: pageSize
        }}
      />
      <Modal
        title={t("AddKeys")}
        open={showFlats}
        onOk={form.submit}
        onCancel={handleAddKeysCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={assignKeys}>
          <Form.Item
            preserve
          >
            <Table columns={flatColumns} dataSource={flats} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
