export const urbanAddresses = {
  "e5a13750-d9c4-4c06-82dd-93e29eb40aff": "2/3 Anastas Mikoyan, Yerevan, Armenia",
  "93eb8574-c9f3-4820-9c16-e69b9a757580": "58/20 Karapet Ulnetsi, Entrance A, Yerevan, Armenia",
  "0f6ce232-afbe-47b6-921c-6bf9d7dfd866": "58/20 Karapet Ulnetsi, Entrance B, Yerevan, Armenia",
  "354530f2-4403-431f-884b-64537efc7574": "58/20 Karapet Ulnetsi, Entrance C, Yerevan, Armenia",
  "9a747611-4523-4ee0-bcdc-f988f16da05c": "Entrance 1, 42 Hakob Paronyan, Yerevan, Armenia",
  "91d278fb-9203-4f17-92df-17f7ddce6f9d": "Entrance 1, 42/1 Hakob Paronyan, Yerevan, Armenia",
  "84a1d110-8b41-4874-99ef-21ed1a7260cc": "Entrance 2, 42/1 Hakob Paronyan, Yerevan , Armenia",
  "10d556dd-fd5b-463f-905e-91d78e780503": "Entrance 5, 41/44 Manandyan, Yerevan, Armenia",
  "8e399a7c-212b-4a6b-a6b7-9841d7b936d7": "Entrance 32, 41/44 Manandyan, Yerevan, Armenia",
  "848804ba-245c-4839-9f01-35c72f39c676": "Tumanyan Street, Entrance 1, Yerevan, Armenia",
  "13e447d4-3afb-4976-b84d-7ba55e04f0b8": "9 Nansen Street, Yerevan, Armenia",
  "1789e29f-7031-4870-8450-71acd5833e39": "Entrance 1, Tsitsernakaberd Highway 1/2 5, Yerevan, Armenia",
  "e89baebd-9899-4e74-b9a7-cdfbaec5ccbe": "Entrance 2, Tsitsernakaberd Highway 1/2 3, Yerevan, Armenia",
  "af9422b2-180e-4283-9ee0-8e88fe2ad59e": "Entrance 1, Tsitsernakaberd Highway 1/2 3, Yerevan, Armenia",
  "0b9a5c71-81d0-4c35-b5de-a8837f2dc833": "Entrance 1, Tsitsernakaberd Highway 1/2 2, Yerevan, Armenia",
  "b7830e44-1fdf-411f-bea7-8f747e5d3634": "Entrance 2, Tsitsernakaberd Highway 1/2 5, Yerevan, Armenia",
  "2161280e-0578-4758-9c79-fa5092342f20": "Entrance 2, Tsitsernakaberd Highway 1/2 2, Yerevan, Armenia",
  "80bf0964-8b40-4a29-8bb2-317256a0ca4d": "Entrance 2, Tsitsernakaberd Highway 1/2 1, Yerevan, Armenia",
  "6c536a1b-ca82-4a52-9745-bb1736dea91a": "Entrance 1, Tsitsernakaberd Highway 1/2 1, Yerevan, Armenia",
  "b51c16d0-71a7-43c2-b160-4df10e89903a": "Tsitsernakaberd Highway 1/2 4, Entrance 1 Yerevan, Armenia",
  "2db5e0b0-4319-4f98-a616-5adbe7f8ee01": "Tsitsernakaberd Highway 1/2 4, Entrance 2 Yerevan, Armenia",
  "1edce5ef-a4af-4fa5-b0f0-c3a202e23ac7": "Entrance 1, 22/2 Hovsep Arghutyan, Yerevan Armenia",
  "8f261697-88c8-4c37-b076-a252f6dd0767": "Entrance 2, 22/2 Hovsep Arghutyan, Yerevan Armenia",
  "0063e673-825f-43bd-9095-887fc2673bab": "Entrance 1, 22 Hovsep Arghutyan, Yerevan, Armenia",
  "cb4ad86b-ff0c-4950-82ae-9597d4385d52": "Entrance 2, 22 Hovsep Arghutyan, Yerevan, Armenia",
  "13aad90d-7daa-4787-8faf-ea4d0ecab2c9": "Entrance 1, 22/1 Hovsep Arghutyan, Yerevan, Armenia",
  "092e656f-b13a-412a-9803-fb839f3f8e07": "Entrance 1, 44 Hakob Paronyan, Yerevan, Armenia",

  "8dcfe110-5612-49f1-abee-51ccd0099262": "Entrance 1, 51/2 Paruyr Sevak, Yerevan, Armania",
  "bef4b9d9-3e7e-4065-92c0-9d068b3acb9d": "Entrance 2, 51/2 Paruyr Sevak , Yerevan, Armenia",
  "450d96af-a6de-4b14-b192-793ff79e136e": "Entrance 3, 51/2 Paruyr Sevak, Yerevan, Armenia",
  "24ae77b1-4d38-4976-89ed-efc68426a8ec": "Entrance 4, 51/2 Paruyr Sevak, Yerevan, Armenia"
};
