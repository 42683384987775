import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { message, RadioChangeEvent } from "antd";

export const usePackages = () => {
  const [isLoading, setLoadign] = useState(false);
  const [packages, setPackages] = useState([]);
  const [checkedID, setCheckedID] = useState("");
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState<boolean>(
    false
  );
  const [isEditModalVisible, setEditModalVisibility] = useState<boolean>(false);
  const { authAxios } = useContext(FetchContext);
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [price, setPrice] = useState<any>("");
  const [packageName, setPackageName] = useState<any>("");
  const [maxUserCount, setMaxUserCount] = useState<any>("");
  const [paymentType, setPaymentType] = useState<string>('month');

  useEffect(() => {
    setLoadign(true);
    const getPackages = async () => {
      const data = await authAxios.get("/admin/packages");
      setPackages(data.data.data);
      setLoadign(false);
    };
    getPackages();
  }, [authAxios]);

  const handleCancelDeletePackage = () => {
    setDeleteModalVisibility(false);
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  const handleCancel = () => {
    setModalVisibility(false);
  };

  const showDeleteModal = (id: string) => {
    setDeleteModalVisibility(true);
    setCheckedID(id);
  };

  const showEditModal = (id: string) => {
    setEditModalVisibility(true);
    setCheckedID(id);
  };

  const handleCancelEdit = () => {
    setEditModalVisibility(false);
  };

  const handleUpload = (values) => {
    let name = values.name;
    let price = values.price;
    let maxUserCount = values.maxUserCount;
    let formData = new FormData();
    formData.append("package", file);

    setUploading(false);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const addPackage = async () => {
      await authAxios
        .post(`admin/packages/upload`, formData, config)
        .then((response) => {
          if (response) {
            const addToPackage = async () => {
              await authAxios
                .post(`admin/packages`, {
                  imageUrl: response?.data?.data?.imageUrl,
                  name,
                  price,
                  maxUserCount,
                  period: paymentType === 'year' ? 365 : 30
                })
                .then((response) => {
                  setModalVisibility(false);
                  message.success("Package has been successfully added");
                  window.location.reload();
                });
            };
            addToPackage();
          }
        })
        .catch((err) => {
          message.error("Package could not be added!");
        });
    };
    addPackage();
    // handle request
  };

  const uploadProps = {
    onRemove: (file) => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
  };

  const deletePackage = async (packageId: string) => {
    await authAxios
      .delete(`/admin/packages/${packageId}`)
      .then((response) => {
        if (response.status === 200) {
          message.success("Package has been successfully deleted!");
          setDeleteModalVisibility(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while deleting this package!");
        setDeleteModalVisibility(false);
      });
  };

  const editPackage = async (packageId: string) => {
    await authAxios
      .put(`admin/packages/${packageId}`, {
        price: price,
        name: packageName,
        maxUserCount: maxUserCount,
        period: paymentType === 'year' ? 365 : 30
      })
      .then((response) => {
        if (response) {
          message.success("Package has been successfully edited!");
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while editing this package!");
      });
  };

  const paymentTypeChange = (e: RadioChangeEvent) => {
    setPaymentType(e.target.value);
  };

  return {
    packages: packages,
    handleUpload,
    uploadProps,
    handleCancel,
    handleCancelEdit,
    isModalVisible,
    showModal,
    deletePackage,
    isDeleteModalVisible,
    showDeleteModal,
    handleCancelDeletePackage,
    isEditModalVisible,
    showEditModal,
    checkedID,
    editPackage,
    setPrice,
    setPackageName,
    packageName,
    price,
    maxUserCount,
    setMaxUserCount,
    isLoading,
    paymentType,
    paymentTypeChange
  };
};
