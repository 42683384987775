import React, { Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { AppShell } from "./components/AppShell";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";
import "./index.css";
import { Devices } from "./pages/Devices/Devices";
import { Services } from "./pages/Services/Services";
import { Slides } from "./pages/Slides/Slides";
import { Login } from "./pages/Login/Login";
import { CurrentUser } from "./pages/CurrentUser/CurrentUser";
import { EditIntercom } from "./pages/EditIntercom/EditIntercom";
import { AuditLogs } from "./pages/AuditLogs/AuditLogs";
import { Intercoms } from "./pages/Intercoms/Intercoms";
import { FlatDetails } from "./pages/FlatDetails/FlatDetails";
import { FlatDetailsElevator } from "./pages/FlatDetails/FlatDetailsElevator";
import { Packages } from "./pages/Packages/Packages";
import { Elevators } from "./pages/Elevators/Elevators";
import { EditElevator } from "./pages/EditElevator/EditElevator";
import { DeviceElevators } from "./pages/DeviceElevators/Elevators";
import { Keys } from "./pages/Keys/Keys";
import { History } from "./pages/History/History";
import { ElevatorHistory} from "./pages/ElevatorHistory/History";

const LoadingFallback = () => (
  <AppShell>
    <div className="p-4">Loading...</div>
  </AppShell>
);

const AuthenticatedRoute = ({ children, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        auth.isAuthenticated() ? (
          <AppShell>{children}</AppShell>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const UnauthenticatedRoutes = () => (
  <>
    <Route exact path="/">
      <Login />
    </Route>
    <Route path="*">
      <div>404</div>
    </Route>
  </>
);

const AppRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <AuthenticatedRoute path="/devices" exact>
            <Devices />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/slides" exact>
            <Slides />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/packages" exact>
            <Packages />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/intercoms" exact>
            <Intercoms />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/elevators" exact>
            <Elevators />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/currentUser" exact>
            <CurrentUser />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}devices/elevators`} exact>
            <DeviceElevators />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}elevators/:id`} exact>
            <EditElevator />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}elevators/:id/history`} exact>
            <ElevatorHistory />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}intercoms/:id`} exact>
            <EditIntercom />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}intercoms/:id/keys`} exact>
            <Keys />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}intercoms/:id/keys/history`} exact>
            <History />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}intercoms/:id/flat/:number`} exact>
            <FlatDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}elevators/:id/flat/:number`} exact>
            <FlatDetailsElevator />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={`${path}intercoms/auditlogs/:id`} exact>
            <AuditLogs />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/services" exact>
            <Services />
          </AuthenticatedRoute>
          <UnauthenticatedRoutes />
        </Switch>
      </Suspense>
    </>
  );
};
const App = () => {
  return (
    <Router>
      <AuthProvider>
        <FetchProvider>
          <div className="bg-gray-100">
            <AppRoutes />
          </div>
        </FetchProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
