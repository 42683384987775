import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { message, notification, RadioChangeEvent } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { unlink } from "fs";

export const useIntercoms = () => {
  const [isLoading, setLoading] = useState(false);
  const [isNotificationSending, setIsNotificationSending] = useState(false);
  const [devices, setDevices] = useState([]);
  const [flats, setFlats] = useState([]);
  const [slides, setSlides] = useState(null);
  const [packages, setPackages] = useState(null);
  const [originalList, setOriginalList] = useState([]);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(
    false
  );
  const [showPopupAlertModal, setShowPopupAlertModal] = useState<boolean>(
    false
  );
  const [userInfoModal, setUserInfoModal] = useState<boolean>(false);
  const [showSliderModal, setShowSliderModal] = useState<boolean>(false);
  const [showPackageModal, setShowPackageModal] = useState<boolean>(false);
  const [slideId, setSlideId] = useState<string>("");
  const [packageId, setPackageId] = useState<string>("");
  const [device, setDevice] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState({});
  const [uploading, setUploading] = useState(false);
  const [showDeleteSliderModal, setShowDeleteSliderModal] = useState<boolean>(
    false
  );
  const [showDeletePackageModal, setShowDeletePackageModal] = useState<boolean>(
    false
  );
  const [selectedIntercom, setSelectedIntercom] = useState<string>("");
  const [slidersFromIntercom, setSlidersFromIntercom] = useState<any[]>([]);
  const [packagesFromIntercom, setPackagesFromIntercom] = useState<any[]>([]);
  const [showPackageToFlatsModal, setShowPackageToFlatsModal] = useState<
    boolean
  >(false);
  const [isLinkServiceModalOpen, toggleLinkServiceModal] = useState(false);
  const [isUnlinkServiceModalOpen, toggleUnlinkServiceModal] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [filteredValue, setFilteredValue] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>('month');

  const [users, setUsers] = useState<any[]>([]);
  const [searchUser, setSearchUser] = useState<string>("");
  const [showSearchUser, setShowSearchUser] = useState<boolean>(false);
  
  const [popupAlerts, setPopupAlerts] = useState<any[]>([]);
  const [showPopupAlerts, setShowPopupAlerts] = useState<boolean>(false);

  const { authAxios, authAxios2 } = useContext(FetchContext);
  const auth = useContext(AuthContext);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const data = await authAxios.get("/admin/slides");
      const packagesData = await authAxios.get("/admin/packages");
      const devicesData = await authAxios.get("/admin/intercoms");

      setDevices(devicesData.data.data.filter((item) => !!item.address));
      setFlats(devicesData.data.data.flats);
      setOriginalList(devicesData.data.data);
      setPackages(packagesData.data.data);
      setOriginalList(packagesData.data.data);
      setSlides(data.data.data);
      setOriginalList(data.data.data);
      setLoading(false);
    };

    getData();
  }, [authAxios]);

  const getDeviceInfo = async () => {
    const data = await authAxios.get(`/admin/intercoms/${device['id']}`);
    if (data) {
      //console.log('DEVICE INFO ==>', data);
      setDeviceInfo(data.data.data);
      setUserInfoModal(true);
    }
  };

  const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setDevice([]);
      setSelectedIntercom(id);
    } else {
      setDevice(row);
      setCheckedIDs([...checkedIDs, id]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setDevice([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(devices.map((it) => it.id));
    }
  };

  const handleSelectedSlide = (e) => {
    setSlideId(e.id);
  };

  const handleSelectedPackage = (e) => {
    setPackageId(e.id);
  };

  const handleAddNotificationOnClick = () => {
    setShowNotificationModal(true);
  };

  const handleAddNotificationCancel = () => {
    setShowNotificationModal(false);
  };

  const handleAddPopupAlertOnClick = () => {
    setShowPopupAlertModal(true);
  };

  const handleAddPopupAlertCancel = () => {
    setShowPopupAlertModal(false);
  };

  const handleAddSliderOnClick = () => {
    setShowSliderModal(true);
  };

  const handleDeleteSliderOnClick = () => {
    setShowDeleteSliderModal(true);
  };

  const handleDeleteSliderCancel = () => {
    setShowDeleteSliderModal(false);
  };

  const handleAddSliderCancel = () => {
    setShowSliderModal(false);
  };

  const handleAddPackageOnClick = () => {
    setShowPackageModal(true);
  };

  const handleDeletePackageOnClick = () => {
    setShowDeletePackageModal(true);
  };

  const handleAddPackageCancel = () => {
    setShowPackageModal(false);
  };

  const handleDeletePackageCancel = () => {
    setShowDeletePackageModal(false);
  };

  const handleAddPackageToFlatsOnClick = () => {
    setShowPackageToFlatsModal(true);
  };

  const handleAddPackageToFlatsCancel = () => {
    setShowPackageToFlatsModal(false);
  };

  const handleUserInfoOnClick = () => {
    getDeviceInfo();
  };

  const handleUserInfoCancel = () => {
    setUserInfoModal(false);
  };

  const addNotifications = async (values: any) => {
    const addNotification = async () => {
      setIsNotificationSending(true);
      await authAxios
        .post(`/notifications/intercom`, {
          deviceIds: checkedIDs,
          title: values.title,
          body: values.body,
        })
        .then((response) => {
          if (response) {
            setShowNotificationModal(false);
            message.success(
              response.data.message != null &&
                response.data.message === "notification.sent"
                ? "Notification has been successfully added"
                : response.data.message
            );
            setIsNotificationSending(false);
          }
          setAreAllChecked(false);
          setCheckedIDs([]);
          setDevice([]);
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Notification could not be added!"
          );
          setIsNotificationSending(false);
        });
    };
    addNotification();
  };

  const addPopupAlert = async (values: any) => {
    const addPopupAlert = async () => {
      setIsNotificationSending(true);
      await authAxios2
        .post(`/admin/popup-alerts`, {
          title: values.title,
          text: values.text,
          url: values.url,
          buttons: [
            {
              text: values.positiveBtn || "Accept",
              action: "accept",
              requestUrl: values.requestUrl || ""
            },
            {
              text: values.negativeBtn || "Cancel",
              action: "cancel"
            }
          ]
        })
        .then((response) => {
          if (response) {
            setShowPopupAlertModal(false);
            if (response.status === 200) {
              message.success("Pop-up alert has been successfully added");
            }
            handleAllPopupAlerts();
          }
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Pop-up alert could not be added!"
          );
        });
        setIsNotificationSending(false);
      };
    addPopupAlert();
  };

  const attachSlide = async () => {
    await authAxios
      .post(`admin/slides/${slideId}`, {
        intercomIds: checkedIDs,
      })
      .then((response) => {
        if (response) {
          message.success("Slide successfully attached");
          setShowSliderModal(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("Slide could not be added!");
      });
  };

  useEffect(() => {
    const getSlidesFromIntercom = async () => {
      await authAxios
        .get(`admin/slides/intercom/${selectedIntercom}`)
        .then((response) => {
          if (response) {
            setSlidersFromIntercom(response.data.data);
          }
        });
    };
    getSlidesFromIntercom();
  }, [selectedIntercom]);

  const deleteSlideFromIntercom = async () => {
    await authAxios
      .delete(`admin/slides`, {
        data: {
          intercomId: selectedIntercom,
          slideId,
        },
      })
      .then((response) => {
        if (response) {
          message.success(
            "Slide has been successfully deleted from selected intercom!"
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while deleting slide!");
      });
  };

  const addPackageToIntercom = async () => {
    await authAxios
      .post(`admin/packages/intercoms/${selectedIntercom}`, {
        packageId: packageId,
        period: paymentType === 'year' ? 365 : 30
      })
      .then((response) => {
        if (response) {
          message.success("Package successfully attached");
          setShowPackageModal(false);
          // window.location.reload();
        }
      })
      .catch((err) => {
        message.error("Package could not be added!");
      });
  };

  useEffect(() => {
    if (showPopupAlerts) {
      handleAllPopupAlerts();
    }
  }, [showPopupAlerts]);
  
  useEffect(() => {
    const getPackagesFromIntercom = async () => {
      await authAxios
        .get(`admin/packages/intercoms/${selectedIntercom}`)
        .then((response) => {
          if (response) {
            setPackagesFromIntercom(response.data.data);
          }
        });
    };
    getPackagesFromIntercom();
  }, [selectedIntercom]);

  useEffect(() => {
    if (searchUser.length >= 7) {
      const getSearchUsers = async () => {
        await authAxios
            .post(`admin/intercoms/search-user`, {
              search: searchUser
            })
            .then((response) => {
              if (response && response.data.data) {
                setUsers(response.data.data);
              } else {
                setUsers([]);
              }
            })
            .catch((err) => {
              console.error(err);
            });
      };
      getSearchUsers();
    } else {
      setUsers([]);
    }
  }, [searchUser]);

  const deletePackageFromIntercom = async () => {
    await authAxios
      .delete(`admin/packages/intercoms/${selectedIntercom}`, {
        data: {
          packageId: packageId,
        },
      })
      .then((response) => {
        if (response) {
          message.success(
            "Package has been successfully deleted from selected intercom!"
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error(
          "There was a problem while deleting the package from the selected intercom!"
        );
      });
  };

  const addPackageToAllFlats = async () => {
    await authAxios
      .post(`admin/packages/flats/${selectedIntercom}`, {
        packageId,
        period: paymentType === 'year' ? 365 : 30
      })
      .then((response) => {
        if (response) {
          message.success("Package successfully attached");
          setShowPackageModal(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("Package could not be added!");
      });
  };

  const handleLinkServiceToIntercoms = async () => {
    const { data } = await authAxios.get("/admin/services");
    setServices(data.data);
    toggleLinkServiceModal(true);
  };
  const handleUnlinkServiceFromIntercoms = async () => {
    const { data } = await authAxios.get("/admin/services");
    setServices(data.data);
    toggleUnlinkServiceModal(true);
  };
  const asyncOperation = async (intercomIds, serviceId, unlink) => {
    try {
      const data = {
        intercomIds,
      };
      unlink
        ? await authAxios.delete(
          `/admin/services/${serviceId}/intercoms/${data.intercomIds}`
        )
        : await authAxios.post(`/admin/services/${serviceId}`, data);
      notification.success({
        message: unlink ? "Successfully unlinked" : "Successfully attached",
        description: unlink
          ? "Serviced unlinked successfully"
          : "Service attached successfully",
      });
    } catch (error) {
      notification.error({
        message: "Something went wrong",
        description:
          error.response?.data?.message === "service.already.attached"
            ? "Service already attached"
            : error.response?.data?.message,
      });
    }
  };
  const linkServiceToIntercom = async () => {
    setLoading(true);
    const promises = selectedServices.map((service) =>
      asyncOperation(checkedIDs, service.id, false)
    );
    await Promise.all(promises);
    toggleLinkServiceModal(false);
    setLoading(false);
    setSelectedServices([]);
  };
  const unlinkServiceFromIntercom = async () => {
    setLoading(true);
    const promises = selectedServices.map((service) =>
      asyncOperation(checkedIDs, service.id, true)
    );
    await Promise.all(promises);
    toggleUnlinkServiceModal(false);
    setLoading(false);
    setSelectedServices([]);
  };

  const handleAllPopupAlerts = async () => {
    await authAxios2
      .get(`/admin/popup-alerts`)
      .then((response) => {
        if (response) {
         setPopupAlerts(response.data);
        } else {
          setPopupAlerts([]);
        }
      });
  }

  const updatePopupAlert = async (values: any) => {
    const updatePopupAlert = async () => {
      await authAxios2
        .put(`/admin/popup-alerts/${values.id}`, {
          title: values.title,
          text: values.text,
          url: values.url,
          pictureUrl: values.pictureUrl,
          isActive: values.isActive,
          buttons: [
            {
              text: values.positiveBtn,
              action: "accept",
              requestUrl: values.requestUrl
            },
            {
              text: values.negativeBtn,
              action: "cancel"
            }
          ]
        })
        .then(async (response) => {
          if (response.status === 200) {
              message.success("Pop-up Alert Has Been Updated Successfully");
              handleAddPopupAlertCancel();
          }
        })
        .catch((err) => {
          message.error(
            "There was a problem with pop-up alert update. Please try again later!"
          );
        })
    };
    await updatePopupAlert();
    await handleAllPopupAlerts();
  }

  const paymentTypeChange = (e: RadioChangeEvent) => {
    setPaymentType(e.target.value);
  };

  return {
    devices: devices,
    changeCheckedValue,
    checkedIDs,
    changeSelectAll,
    device,
    addNotifications,
    showNotificationModal,
    handleAddNotificationOnClick,
    showSliderModal,
    handleAddNotificationCancel,
    areAllChecked,
    handleAddSliderOnClick,
    handleAddSliderCancel,
    handleAddPackageOnClick,
    handleDeletePackageOnClick,
    showPackageModal,
    showDeletePackageModal,
    handleAddPackageCancel,
    handleDeletePackageCancel,
    uploading,
    slides,
    packages,
    handleSelectedSlide,
    handleSelectedPackage,
    attachSlide,
    addPackageToIntercom,
    deletePackageFromIntercom,
    slideId,
    packageId,
    handleDeleteSliderOnClick,
    showDeleteSliderModal,
    handleDeleteSliderCancel,
    slidersFromIntercom,
    packagesFromIntercom,
    deleteSlideFromIntercom,
    setSelectedIntercom,
    handleAddPackageToFlatsOnClick,
    handleAddPackageToFlatsCancel,
    showPackageToFlatsModal,
    addPackageToAllFlats,
    isLoading,
    isNotificationSending,
    handleLinkServiceToIntercoms,
    isLinkServiceModalOpen,
    linkServiceToIntercom,
    toggleLinkServiceModal,
    services,
    selectedServices,
    setSelectedServices,
    setFilteredValue,
    filteredValue,
    unlinkServiceFromIntercom,
    isUnlinkServiceModalOpen,
    handleUnlinkServiceFromIntercoms,
    toggleUnlinkServiceModal,
    userInfoModal,
    handleUserInfoOnClick,
    handleUserInfoCancel,
    deviceInfo,
    paymentType,
    paymentTypeChange,
    users,
    setSearchUser,
    showSearchUser,
    setShowSearchUser,
    popupAlerts,
    showPopupAlerts,
    setShowPopupAlerts,
    updatePopupAlert,
    handleAddPopupAlertOnClick,
    handleAddPopupAlertCancel,
    addPopupAlert,
    showPopupAlertModal
  };
};
