import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
  AutoComplete,
} from "antd";
import { NewServiceModal } from "./components/NewServiceModal";
import { useServices } from "./useServices";
import { AuthContext } from "../../context/AuthContext";
import { Simulate } from "react-dom/test-utils";
import {useTranslation} from "react-i18next";

const { Option } = AutoComplete;

interface Item {
  id: string;
  name: string;
  paymentType: number;
  price: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const {t} = useTranslation();
  const inputNode =
    dataIndex === "priceType" ? (
      <Select defaultValue={`flexible`} style={{ width: 100 }}>
        <Option value={"flexible"}>{t("Flexible")}</Option>
        <Option value={"fixed"}>{t("Fixed")}</Option>
        <Option value={"flexible_days"}>{t("FlexibleDays")}</Option>
      </Select>
    ) : inputType === "number" ? (
      <InputNumber />
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const Services: React.FC = () => {
  const {
    isLoading,
    services,
    createService,
    deleteService,
    editService,
  } = useServices();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const auth = useContext(AuthContext);
  const {t} = useTranslation()

  const isEditing = (record: Item) => record.id === editingKey;
  const edit = (record: Partial<Item> & { id: React.Key }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        editService(newData[0], id);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      width: "30%",
      editable: true,
    },
    {
      title: t("Price"),
      dataIndex: "price",
      width: "20%",
      editable: true,
    },
    {
      title: t("PaymentType"),
      dataIndex: "priceType",
      width: "15%",
      editable: true,
      render: (priceType) => {
        return <div>{`${priceType === "fixed" ? t("Fixed") : priceType === "flexible_days" ? t("FlexibleDays") : t("Flexible")}`}</div>;
      },
    },
    {
      title: t("Unit"),
      dataIndex: "unitName",
      width: "15%",
      editable: true,
    },
    {
      title: t("Operations"),
      width: "30%",
      dataIndex: "operation",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              {t("Save")}
            </Typography.Link>
            <Popconfirm title={t("SureToCancel")} onConfirm={cancel}>
              <a>{t("Cancel")}</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            {" "}
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ fontSize: '12px' }}
            >
              {t("Edit")}
            </Typography.Link>
            <Popconfirm
                title={t("SureToDelete")}
                onConfirm={() => {
                  deleteService(record.id);
                }}
              >
                <Typography.Link type="danger" style={{ marginLeft: 16, fontSize: '12px' }}>
                  {t("Delete")}
                </Typography.Link>
              </Popconfirm>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <NewServiceModal createService={createService} />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={isLoading}
          bordered
          dataSource={services}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  );
};
