import React from "react";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Space,
  Table,
  Upload,
  AutoComplete,
  Select
} from "antd";
import { useSlides } from "./useSlides";
import { DeleteTwoTone, EditTwoTone, UploadOutlined } from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const { Option } = AutoComplete;

export const Slides = () => {
  const {
    slides,
    handleUpload,
    uploadProps,
    handleCancelEdit,
    isModalVisible,
    isDeleteModalVisible,
    showModal,
    showDeleteModal,
    handleCancelDeleteSlide,
    isEditModalVisible,
    showEditModal,
    checkedID,
    deleteSlide,
    editSlide,
    setHyperlink,
    setSlideName,
    setSlideType,
    slideType,
    slideName,
    hyperlink,
    handleCancelAddModal,
    setImageURL,
    imageURL,
    file
  } = useSlides();

  const [form] = Form.useForm();
  const {t} = useTranslation()

  const columns = [
    {
      title: t("Name"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("Image"),
      key: "imageUrl",
      dataIndex: "imageUrl",
      render: (imageUrl) => <Image width={100} src={`${imageUrl}`} />,
    },
    {
      title: t("Type"),
      key: "type",
      dataIndex: "type",
    },
    {
      title: t("Link"),
      key: "link",
      dataIndex: "link",
      width: 100
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, name) => {
        return (
          <Space size="middle">
            <EditTwoTone
              onClick={() => {
                showEditModal(id);
                setHyperlink(name.link);
                setSlideName(name.name);
                setImageURL(name.imageUrl);
                setSlideType(name.type);
              }}
            />
            <DeleteTwoTone
              twoToneColor="red"
              onClick={() => showDeleteModal(id)}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Button onClick={showModal}> {t("AddSlide")} </Button>
      <Divider />
      <Modal
        title={t("AddSlide")}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancelAddModal}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={handleUpload}>
          <Form.Item label={t("Slide")} name="slide">
            <Upload
              multiple={false}
              maxCount={1}
              {...uploadProps}
              accept={".png,.jpg,.jpeg"}
            >
              <Button icon={<UploadOutlined />}>{t("UploadSlide")}</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label={t("Name")}
            name="name"
            rules={[
              { required: true, message: "Please input a name for the slide!" },
            ]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("Hyperlink")}
            name="link"
            rules={[{ required: true, message: "Please input the link!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item label={t("Type")} name="type" preserve>
            <Select
              defaultValue={"INTERCOM"}
              style={{ width: 200 }}
            >
              <Option value={"INTERCOM"}>{t("Intercom")}</Option>
              <Option value={"DEFAULT"}>{t("Default")}</Option>
              <Option value={"DEFAULT_BOON"}>{t("DefaultBoon")}</Option>
              <Option value={"DEFAULT_URBAN"}>{t("DefaultUrban")}</Option>
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("RemoveSlideConfirm")}
        visible={isDeleteModalVisible}
        onOk={form.submit}
        onCancel={handleCancelDeleteSlide}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Button onClick={() => deleteSlide(checkedID)}>{t("Yes")}</Button>
      </Modal>

      <Modal
        title={t("EditSlide")}
        visible={isEditModalVisible}
        onOk={form.submit}
        onCancel={handleCancelEdit}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={editSlide}>
          <Form.Item label={t("Name")} name="name" initialValue={slideName} preserve>
            <Input type="text" onChange={(e) => setSlideName(e.target.value)} />
          </Form.Item>
          <Form.Item
            label={t("Hyperlink")}
            name="link"
            initialValue={hyperlink}
            preserve
          >
            <Input type="text" onChange={(e) => setHyperlink(e.target.value)} />
          </Form.Item>
          <Form.Item label={t("Type")} name="type" preserve>
            <Select
              defaultValue={`${slideType}`}
              style={{ width: 200 }}
              onChange={(value) => setSlideType(value)}
            >
              <Option value={"INTERCOM"}>{t("Intercom")}</Option>
              <Option value={"DEFAULT"}>{t("Default")}</Option>
              <Option value={"DEFAULT_BOON"}>{t("DefaultBoon")}</Option>
              <Option value={"DEFAULT_URBAN"}>{t("DefaultUrban")}</Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("Slide")} name="slide">
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Image width={100} src={`${imageURL}`} />
              <div style={{marginLeft: '15px'}}>
                <Upload
                  multiple={false}
                  maxCount={1}
                  {...uploadProps}
                  accept={".png,.jpg,.jpeg"}
                  listType="picture-card"
                  showUploadList={file ? true : false}
                >
                  <Button icon={<UploadOutlined />}>{t("EditSlide")}</Button>
                </Upload>
              </div>
            </div>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>

      <Table columns={columns} dataSource={slides} />
    </div>
  );
};
