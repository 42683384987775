import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";
import { message, notification } from "antd";

export const useElevators = () => {
  const [isLoading, setLoading] = useState(false);
  const [elevators, setElevators] = useState([]);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [elevator, setElevator] = useState([]);
  const [elevatorInfo, setElevatorInfo] = useState({});
  const [selectedElevator, setSelectedElevator] = useState<string>("");
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(
    false
  );
  const [isNotificationSending, setIsNotificationSending] = useState(false);
  const [services, setServices] = useState([]);
  const [isLinkServiceModalOpen, toggleLinkServiceModal] = useState(false);
  const [isUnlinkServiceModalOpen, toggleUnlinkServiceModal] = useState(false);
  const [showSliderModal, setShowSliderModal] = useState<boolean>(false);
  const [showDeleteSliderModal, setShowDeleteSliderModal] = useState<boolean>(
    false
  );
  const [showPackageModal, setShowPackageModal] = useState<boolean>(false);
  const [showPackageToFlatsModal, setShowPackageToFlatsModal] = useState<boolean>(false);
  const [showDeletePackageModal, setShowDeletePackageModal] = useState<boolean>(
    false
  );
  const [slidersFromIntercom, setSlidersFromIntercom] = useState<any[]>([]);
  const [slideId, setSlideId] = useState<string>("");
  const [selectedIntercom, setSelectedIntercom] = useState<string>("");
  const [slides, setSlides] = useState(null);
  const [packages, setPackages] = useState(null);
  const [packageId, setPackageId] = useState<string>("");
  const [packagesFromIntercom, setPackagesFromIntercom] = useState<any[]>([]);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [originalList, setOriginalList] = useState([]);
  const [filteredValue, setFilteredValue] = useState<string>("");

  const auth = useContext(AuthContext);
  const { authAxios, authAxios2 } = useContext(FetchContext);

  const getData = async () => {
    setLoading(true);
    const data = await authAxios.get("/admin/slides");
    const packagesData = await authAxios.get("/admin/packages");
    const elevatorsData = await authAxios2.get(auth.authState.userInfo.isSuperAdmin ? "/admin/elevators" : `/admin/elevators/user/${auth.authState.userInfo.id}`);
   
    setElevators(
      elevatorsData.data.elevatorsJson.filter((item) => !!item.address)
    );
    setPackages(packagesData.data.data);
    setOriginalList(packagesData.data.data);
    setSlides(data.data.data);
    setOriginalList(data.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [authAxios]);

  const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setElevator([]);
      setSelectedElevator(id);
    } else {
      setElevator(row);
      setCheckedIDs([...checkedIDs, id]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setElevator([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(elevators.map((it) => it.id));
    }
  };

  const handleAddNotificationOnClick = () => {
    setShowNotificationModal(true);
  };

  const handleAddNotificationCancel = () => {
    setShowNotificationModal(false);
  };

  const addNotifications = async (values: any) => {
    const addNotification = async () => {
      setIsNotificationSending(true);
      await authAxios
        .post(`/notifications/intercom`, {
          deviceIds: checkedIDs,
          title: values.title,
          body: values.body,
        })
        .then((response) => {
          if (response) {
            setShowNotificationModal(false);
            message.success(
              response.data.message != null &&
                response.data.message === "notification.sent"
                ? "Notification has been successfully added"
                : response.data.message
            );
            setIsNotificationSending(false);
          }
          setAreAllChecked(false);
          setCheckedIDs([]);
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Notification could not be added!"
          );
          setIsNotificationSending(false);
        });
    };
    addNotification();
  };

  const handleLinkServiceToIntercoms = async () => {
    const { data } = await authAxios.get("/admin/services");
    setServices(data.data);
    toggleLinkServiceModal(true);
  };

   const handleUnlinkServiceFromIntercoms = async () => {
    const { data } = await authAxios.get("/admin/services");
    setServices(data.data);
    toggleUnlinkServiceModal(true);
  };

  const handleAddSliderOnClick = () => {
    setShowSliderModal(true);
  };

  const handleDeleteSliderOnClick = () => {
    setShowDeleteSliderModal(true);
  };

  const handleDeleteSliderCancel = () => {
    setShowDeleteSliderModal(false);
  };

  const handleAddSliderCancel = () => {
    setShowSliderModal(false);
  };

  const handleAddPackageOnClick = () => {
    setShowPackageModal(true);
  };

  const handleAddPackageToFlatsOnClick = () => {
    setShowPackageToFlatsModal(true);
  };

  const handleDeletePackageOnClick = () => {
    setShowDeletePackageModal(true);
  };

  const handleSelectedSlide = (e) => {
    setSlideId(e.id);
  };

  const deleteSlideFromIntercom = async () => {
    await authAxios
      .delete(`admin/slides`, {
        data: {
          intercomId: selectedIntercom,
          slideId,
        },
      })
      .then((response) => {
        if (response) {
          message.success(
            "Slide has been successfully deleted from selected intercom!"
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while deleting slide!");
      });
  };

  const attachSlide = async () => {
    await authAxios
      .post(`admin/slides/${slideId}`, {
        intercomIds: checkedIDs,
      })
      .then((response) => {
        if (response) {
          message.success("Slide successfully attached");
          setShowSliderModal(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("Slide could not be added!");
      });
  };

  const handleAddPackageCancel = () => {
    setShowPackageModal(false);
  };

  const handleSelectedPackage = (e) => {
    setPackageId(e.id);
  };

  const addPackageToIntercom = async () => {
    await authAxios
      .post(`admin/packages/intercoms/${selectedIntercom}`, {
        packageId: packageId,
      })
      .then((response) => {
        if (response) {
          message.success("Package successfully attached");
          setShowPackageModal(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("Package could not be added!");
      });
  };

  const handleDeletePackageCancel = () => {
    setShowDeletePackageModal(false);
  };

  useEffect(() => {
    const getPackagesFromIntercom = async () => {
      await authAxios
        .get(`admin/packages/intercoms/${selectedIntercom}`)
        .then((response) => {
          if (response) {
            setPackagesFromIntercom(response.data.data);
          }
        });
    };
    getPackagesFromIntercom();
  }, [selectedIntercom]);

  const deletePackageFromIntercom = async () => {
    await authAxios
      .delete(`admin/packages/intercoms/${selectedIntercom}`, {
        data: {
          packageId: packageId,
        },
      })
      .then((response) => {
        if (response) {
          message.success(
            "Package has been successfully deleted from selected intercom!"
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error(
          "There was a problem while deleting the package from the selected intercom!"
        );
      });
  };

  const handleAddPackageToFlatsCancel = () => {
    setShowPackageToFlatsModal(false);
  };

  const addPackageToAllFlats = async () => {
    await authAxios
      .post(`admin/packages/flats/${selectedIntercom}`, {
        packageId,
      })
      .then((response) => {
        if (response) {
          message.success("Package successfully attached");
          setShowPackageModal(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("Package could not be added!");
      });
  };

  const asyncOperation = async (intercomIds, serviceId, unlink) => {
    try {
      const data = {
        intercomIds,
      };
      unlink
        ? await authAxios.delete(
            `/admin/services/${serviceId}/intercoms/${data.intercomIds}`
          )
        : await authAxios.post(`/admin/services/${serviceId}`, data);
      notification.success({
        message: unlink ? "Successfully unlinked" : "Successfully attached",
        description: unlink
          ? "Serviced unlinked successfully"
          : "Service attached successfully",
      });
    } catch (error) {
      notification.error({
        message: "Something went wrong",
        description:
          error.response?.data?.message === "service.already.attached"
            ? "Service already attached"
            : error.response?.data?.message,
      });
    }
  };

  const linkServiceToIntercom = async () => {
    setLoading(true);
    const promises = selectedServices.map((service) =>
      asyncOperation(checkedIDs, service.id, false)
    );
    await Promise.all(promises);
    toggleLinkServiceModal(false);
    setLoading(false);
    setSelectedServices([]);
  };

  const unlinkServiceFromIntercom = async () => {
    setLoading(true);
    const promises = selectedServices.map((service) =>
      asyncOperation(checkedIDs, service.id, true)
    );
    await Promise.all(promises);
    toggleUnlinkServiceModal(false);
    setLoading(false);
    setSelectedServices([]);
  };

  
  return {
    elevators: elevators,
    checkedIDs,
    changeCheckedValue,
    changeSelectAll,
    areAllChecked,
    elevator,
    elevatorInfo,
    setSelectedElevator,
    isLoading,
    handleAddNotificationOnClick,
    showNotificationModal,
    handleAddNotificationCancel,
    addNotifications,
    isNotificationSending,
    handleLinkServiceToIntercoms,
    handleUnlinkServiceFromIntercoms,
    handleAddSliderOnClick,
    handleDeleteSliderOnClick,
    handleAddPackageOnClick,
    handleAddPackageToFlatsOnClick,
    handleDeletePackageOnClick,
    showDeleteSliderModal,
    handleDeleteSliderCancel,
    slidersFromIntercom,
    handleSelectedSlide,
    slideId,
    deleteSlideFromIntercom,
    showSliderModal,
    handleAddSliderCancel,
    slides,
    attachSlide,
    showPackageModal,
    handleAddPackageCancel,
    packages,
    handleSelectedPackage,
    packageId,
    addPackageToIntercom,
    showDeletePackageModal,
    handleDeletePackageCancel,
    packagesFromIntercom,
    deletePackageFromIntercom,
    showPackageToFlatsModal,
    handleAddPackageToFlatsCancel,
    addPackageToAllFlats,
    isLinkServiceModalOpen,
    linkServiceToIntercom,
    toggleLinkServiceModal,
    services,
    setSelectedServices,
    isUnlinkServiceModalOpen,
    unlinkServiceFromIntercom,
    toggleUnlinkServiceModal,
    setFilteredValue,
    filteredValue
  };
};
