import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useParams } from "react-router-dom";

export const useAuditLogs = () => {
  const [AuditLogs, setAuditLogs] = useState([]);
  const [deviceID, setDeviceID] = useState(useParams);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const { authAxios } = useContext(FetchContext);

  useEffect(() => {
    const getAuditLogs = async () => {
      const data = await authAxios.get(`/history/intercom/${deviceID.id}`);
      setAuditLogs(data.data.data);
    };
    getAuditLogs();
  }, [authAxios]);

  const handleIsImageVisible = (imageUrl) => {
    setIsImageVisible(true);
    setImageUrl(imageUrl);
  };

  const handleDetailsOk = () => {
    setIsImageVisible(false);
    setImageUrl("");
  };

  const handleDetailsCancel = () => {
    setIsImageVisible(false);
    setImageUrl("");
  };

  return {
    AuditLogs: AuditLogs,
    handleIsImageVisible: handleIsImageVisible,
    imageUrl: imageUrl,
    isImageVisible: isImageVisible,
    handleDetailsCancel: handleDetailsCancel,
    handleDetailsOk: handleDetailsOk,
  };
};
