import React from "react";
import { Button, Modal, Table, Tag, Image } from "antd";
import { useAuditLogs } from "./useAuditLogs";

export const AuditLogs = () => {
  const columns = [
    {
      title: "Flat Number",
      key: "flatNumber",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address.flatNumber}`}</div>;
      },
    },
    {
      title: "Call Time",
      key: "callTime",
      dataIndex: "callTime",
    },
    {
      title: "Opened Time",
      key: "doorOpenTime",
      dataIndex: "doorOpenTime",
    },
    {
      title: "Opened Time",
      key: "doorOpenTime",
      dataIndex: "doorOpenTime",
    },
    {
      title: "Opened By",
      key: "openedByName",
      dataIndex: "openedByName",
    },
    {
      title: "Screenshot",
      key: "screenShotUrl",
      dataIndex: "screenShotUrl",
      render: (screenShotUrl) => {
        return (
          <Button
            type="primary"
            disabled={!screenShotUrl}
            onClick={() => handleIsImageVisible(screenShotUrl)}
          >
            ScreenShot
          </Button>
        );
      },
    },
  ];

  const {
    AuditLogs,
    handleIsImageVisible,
    imageUrl,
    isImageVisible,
    handleDetailsOk,
    handleDetailsCancel,
  } = useAuditLogs();

  return (
    <div>
      {isImageVisible && imageUrl && (
        <Modal
          title="Screenshot"
          visible={isImageVisible}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={handleDetailsCancel}
          onOk={handleDetailsOk}
        >
          <Image src={imageUrl} width={200}></Image>
        </Modal>
      )}
      <Table columns={columns} dataSource={AuditLogs} />
    </div>
  );
};
