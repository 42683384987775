import React from "react";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";

interface DataType {
  id: string;
  name: string;
  priceType: string;
  unitName: string;
  price: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Price",
    dataIndex: "price",
  },
  {
    title: "Price type",
    dataIndex: "priceType",
    render: (priceType: string) => (
      <span>
        {priceType === "flexible" ? (
          <Tag color="geekblue">{priceType.toUpperCase()}</Tag>
        ) : priceType === "flexible_days" ? (
          <Tag color="blue">{priceType.toUpperCase()}</Tag>
        ) : (
          <Tag color="green">{priceType.toUpperCase()}</Tag>
        )}
      </span>
    ),
  },
  {
    title: "Unit name",
    dataIndex: "unitName",
  },
];

export const ServicesList = ({ services, setSelectedServices }) => {
  const rowSelection: TableRowSelection<DataType> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedServices(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedServices(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedServices(selectedRows);
    },
  };
  return (
    <Table
      rowSelection={{
        ...rowSelection,
      }}
      columns={columns}
      dataSource={services.map((el) => ({ ...el, key: el.id }))}
      pagination={false}
    />
  );
};
