import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${authContext.authState.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log("error code", code);
      }
      return Promise.reject(error);
    }
  );

  const authAxios2 = axios.create({
    baseURL: process.env.REACT_APP_ELEVATORS_API_URL,
  });

  authAxios2.interceptors.request.use(
    (config) => {
      console.log("Config ==>", config);
      //config.headers.Authorization = `Bearer ${authContext.authState.token}`;
      //config.headers.key = "Content-Type";
      //config.headers.value = "application/json";
      //config.headers.type = "text";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios2.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log("error code", code);
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        authAxios2,
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
