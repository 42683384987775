import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { message } from "antd";

export const useSlides = () => {
  const [slides, setSlides] = useState([]);
  const [checkedID, setCheckedID] = useState("");
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState<boolean>(
    false
  );
  const [isEditModalVisible, setEditModalVisibility] = useState<boolean>(false);
  const { authAxios } = useContext(FetchContext);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [hyperlink, setHyperlink] = useState<any>("");
  const [slideName, setSlideName] = useState<any>("");
  const [slideType, setSlideType] = useState<any>("");
  const [imageURL, setImageURL] = useState<any>("");

  const getSlides = async () => {
    const data = await authAxios.get("/admin/slides");
    setSlides(data.data.data);
  };

  useEffect(() => {
    getSlides();
  }, [authAxios]);

  const handleCancelDeleteSlide = () => {
    setDeleteModalVisibility(false);
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  const handleCancelAddModal = () => {
    setModalVisibility(false);
  };

  const showDeleteModal = (id: string) => {
    setDeleteModalVisibility(true);
    setCheckedID(id);
  };

  const showEditModal = (id: string) => {
    setEditModalVisibility(true);
    setCheckedID(id);
  };

  const handleCancelEdit = () => {
    setEditModalVisibility(false);
  };

  const handleUpload = (values) => {
    let link = values.link;
    let name = values.name;
    let type = values.type;
    let formData = new FormData();
    formData.append("slide", file);

    setUploading(false);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const addSlide = async () => {
      await authAxios
        .post(`admin/slides/upload`, formData, config)
        .then((response) => {
          if (response) {
            const addToIntercom = async () => {
              await authAxios
                .post(`admin/slides`, {
                  imageUrl: response?.data?.data?.imageUrl,
                  link,
                  name,
                  type
                })
                .then((response) => {
                  setModalVisibility(false);
                  message.success("Slide has been successfully added");
                  window.location.reload();
                });
            };
            addToIntercom();
          }
        })
        .catch((err) => {
          message.error("Slide could not be added!");
        });
    };
    addSlide();
    // handle request
  };

  const uploadProps = {
    onRemove: (file) => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
  };

  const deleteSlide = async (slideId: string) => {
    await authAxios
      .delete(`/admin/slides/${slideId}`)
      .then((response) => {
        if (response.status === 200) {
          message.success("Slide has been successfully deleted!");
          setDeleteModalVisibility(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while deleting this slide!");
        setDeleteModalVisibility(false);
      });
  };

  const editSlide = async (values) => {
    let newImageUrl = imageURL;
    let formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (values.slide) {
      formData.append("slide", file);
      try {
        const response = await authAxios.post(`admin/slides/upload`, formData, config);
        if (response) {
          //console.log('Response ==>', response);
          newImageUrl = response?.data?.data?.imageUrl;
        }
      } catch (e) {
        console.log('Slide upload error ==>', e);
        message.error("There was a problem while editing this slide!");
      }
    }

    await authAxios
      .put(`admin/slides/${checkedID}`, {
        link: hyperlink,
        name: slideName,
        type: slideType,
        imageUrl: newImageUrl
      })
      .then((response) => {
        if (response) {
          if (newImageUrl !== imageURL) {
            message.success("Slide has been successfully edited!");
          }
          getSlides();
          setEditModalVisibility(false);
          setFile(null);
          //window.location.reload();
        }
      })
      .catch((err) => {
        message.error("There was a problem while editing this slide!");
      });
  };

  return {
    slides: slides,
    handleUpload,
    uploadProps,
    handleCancelEdit,
    isModalVisible,
    showModal,
    deleteSlide,
    isDeleteModalVisible,
    showDeleteModal,
    handleCancelDeleteSlide,
    isEditModalVisible,
    showEditModal,
    checkedID,
    editSlide,
    setHyperlink,
    setSlideName,
    setSlideType,
    slideType,
    slideName,
    hyperlink,
    handleCancelAddModal,
    setImageURL,
    imageURL,
    file
  };
};
