import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useParams, useLocation } from "react-router-dom";
import { message } from "antd";

export const useKeys = () => {
  const {state} = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [intercomId, setIntercomId] = useState(useParams);
  const [sessionId, setSessionId] = useState(null);
  const [sessionState, setSessionState] = useState(null);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [hexes, setHexes] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showFlats, setShowFlats] = useState<boolean>(false);
  const [flats, setFlats] = useState([]);
  const [selectedFlat, setSelectedFlat] = useState("");
  const [showNFCConfirm, setShowNFCConfirm] = useState(false);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [relatedIntercoms, setRelatedIntercoms] = useState(null);
  const [relatedFlats, setRelatedFlats] = useState([]);
  const [address, setAddress] = useState(state["address"].address);
  const { authAxios, authAxios2 } = useContext(FetchContext); 

  useEffect(() => {
    getKeys();
    getRelatedIntercoms();
  }, [authAxios2]);

  useEffect(() => {
    if (sessionId) {
      getSessionState();
    }
  }, [sessionId]);

  useEffect(() => {
    if (showFlats) {
      getFlats();
    }
  }, [showFlats]);

  const getRelatedIntercoms = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${intercomId.id}/relations`);
      if (response) {
        //console.log('GET INTERCOMS RESPONSE ==>', response.data.data);
        setRelatedIntercoms(response.data.data.relations);
      }
    } catch (e) {
      console.log('GET INTERCOMS ERROR ==>', e);
    }
  };

  const getRelatedFlats = async (flatId) => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${intercomId.id}/relatedFlats/${flatId}`);
      if (response) {
        console.log('Related Flats ==>', response);
        setRelatedFlats(response.data.data.relatedFlats);
      }
    } catch (e) {
      console.log('Related Flats ERROR ==>', e);

    }
  };

  const getKeys = async () => {
    setLoading(true);
    try {
      const response = await authAxios2.get(`/admin/keys/intercom/${intercomId.id}`);
      if (response) {
        const newArray = [];
        for (let data of response.data) {
          let newData = {
            createdAt: data.createdAt,
            flat_id: data.flat_id,
            id: data.id,
            intercom_id: data.intercom_id,
            code: data.key,
            updatedAt: data.updatedAt,
            address: data.address,
          };
          newArray.push(newData);
        };
        setKeys(newArray);
        setLoading(false);
      }
    } catch (e) {
      console.log('GET KEYS ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Keys could not be shown. Please refresh."
      );
    }
  };

  const getSessionState = async () => {
    try {
      const response = await authAxios2.get(`/admin/keys/session/${sessionId}`);
      if (response) {
        console.log('GET SESSION INFO ==>', response);
        setSessionId(response.data.id);
        setSessionState(response.data.event);
      }
    } catch (e) {
      console.log('GET SESSION INFO ERROR ==>', e);

    }
  };

  const getFlats = async () => {
    try {
      const response = await authAxios.get(`/admin/intercoms/${intercomId.id}`);
      if (response) {
        //console.log('GET FLATS ==>', response);
        setFlats(response.data.data.flats);
      }
    } catch (e) {
      console.log('GET FLATS ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Flats could not be shown. Please try again later."
      );

    }
  };

  const handleSessionState = async () => {
    try {
      const response = await authAxios2.post(`/admin/keys/session`, {
        state: sessionState === "started" ? "ended" : "started",
        intercom_id: intercomId.id
      });
      if (response) {
        //console.log('SESSION STATE ==>', response);
        setSessionId(response.data.id);
        setSessionState(response.data.event);
        message.success(
          response?.data?.message != null
            ? response.data.message
            : `Session has been successfully ${response.data.event}!`
        );
      }
    } catch (e) {
      console.log('SESSION STATE FOR KEYS ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Session state could not be changed."
      );

    }
  };

  const assignKeys = async () => {
    let data = [
      {
        flat_id: selectedFlat.toString(),
        hexes: hexes,
        device_id: intercomId.id
      }
    ];

    if (relatedIntercoms) {
      for (let relation of relatedIntercoms.relatedIntercomIds) {
        for (let flat of relatedFlats) {
          if (relatedIntercoms.baseIntercom === intercomId.id) {
            if (flat.intercomId === relation) {
              data.push({
                flat_id: flat.flatId,
                hexes: hexes,
                device_id: relation
              });
            }
          } else {
            data.push({
              flat_id: flat.flatId,
              hexes: hexes,
              device_id: relatedIntercoms.baseIntercom
            });
          }
        }
      }
    }

    for (let item of data) {
      try {
        const response = await authAxios2.post(`/admin/keys/create`, item);
        if (response) {
          //console.log('Add Key ==>', response);
          message.success(
            response.data.message != null
              ? response.data.message
              : "Key has been successfully added"
          );
        }
      } catch (err) {
        console.log('Add Key ERROR ==>', err);
        message.error(
          err.response.data.message != null
            ? err.response.data.message
            : "Key could not be added!"
        );
      }
    }

    setShowFlats(false);
    getKeys();
  };

  // const assignKeys = async () => {
  //   try {
  //       const response = await authAxios2.post(`/admin/keys/upload`, {
  //           flat_id: selectedFlat,
  //           keys: checkedIDs
  //       });
  //       if(response) {
  //           //console.log('ASSIGN KEYS ==>', response);
  //           setShowFlats(false);  
  //           message.success(
  //             response?.data?.message != null
  //               ? response.data.message
  //               : "Keys has been successfully assign to flat!"
  //           ); 
  //       }
  //   } catch (e) {
  //       console.log('ASSIGN KEYS ERROR ==>', e);
  //       message.error(
  //         e.response.data.message != null
  //           ? e.response.data.message
  //           : "Keys could not be assigned!"
  //       );      
  //   }
  // };

  const handleAddKeysOnClick = () => {
    setShowFlats(true);
  };

  const handleAddKeysCancel = () => {
    setShowFlats(false);
  };

  const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setHexes(hexes.filter((it) => it != row.code));
    } else {
      setCheckedIDs([...checkedIDs, id]);
      setHexes([...hexes, row.code]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(keys.map((it) => it.id));
    }
  };

  const changeCheckedFlats = (row, id) => {
    if (selectedFlat === id) {
      setSelectedFlat("");
    } else {
      setSelectedFlat(id);
      getRelatedFlats(id);
    }
  };

  const handleDeleteNFCCode = async () => {
    try {
      const response = await authAxios2.post(`/admin/keys/delete`, {
        keys: checkedIDs
      });
      if (response) {
        //console.log('DELETE KEY ==>', response);
        getKeys();
        setCheckedIDs([]);
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "Key has been successfully deleted!"
        );
      }
    } catch (e) {
      console.log('DELETE KEY ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Keys could not be deleted!"
      );
    }
  };

  const handleCancelNFCCodeDelete = () => {
    setShowNFCConfirm(false);
  };

  return {
    changeCheckedValue,
    checkedIDs,
    keys,
    isLoading,
    handleSessionState,
    sessionState,
    showFlats,
    handleAddKeysOnClick,
    handleAddKeysCancel,
    assignKeys,
    flats,
    setSelectedFlat,
    selectedFlat,
    changeCheckedFlats,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete,
    intercomId,
    areAllChecked,
    changeSelectAll,
    address
  };
};
