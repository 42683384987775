import React, {useContext, useEffect, useRef, useState} from 'react';
import type {InputRef} from 'antd';
import {Button, Form, Input, Popconfirm, Table, Checkbox, Modal, Radio, Space} from 'antd';
import type {FormInstance} from 'antd/es/form';
import {useTranslation} from "react-i18next";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) 
            :
        (
            <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
                {children}
            </div>
        )
    }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface FlatType {
    key: React.Key;
    flatNumber: string;
    exportId: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const IDTable: React.FC<{ flats: any[], selectedService: any,serviceFlatValues:any,services:any,editTotal:any,handleTableChange: any,currentPage: any,limit: any,areAllChecked:any,checkedIDs:any[],changeSelectAll:any,changeCheckedValue:any,setSelectedFlat:any, editExportIdValue: any}> = ({flats ,selectedService, handleTableChange, currentPage, limit, areAllChecked, checkedIDs, changeSelectAll, changeCheckedValue, setSelectedFlat,editExportIdValue}) => {
    const {t} = useTranslation();
    const [dataSource, setDataSource] = useState<FlatType[]>(flats);
    const [change, setChange] = useState(null);

    useEffect(()=> {
        const newFlats = flats.map(el => {
            return {
                key: el.id,
                flatNumber: el.flatNumber,
                exportId: el.exportId
            }
        })
        setDataSource(newFlats);
    },[flats,selectedService]);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
     {
      dataIndex: "id",
      width: '10%',
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {    
        return (
          <Checkbox
            value={row["key"]}
            checked={checkedIDs.includes(row["key"])}
            // disabled={row["charged"]}
            onClick={() => {
              changeCheckedValue(row, row["key"]);
              setSelectedFlat(row["key"]);
            }}
          />
        );
      },
    },
    {
      title: "Flat Number",
      dataIndex: "flatNumber",
      width: "20%",
      key: "flatNumber",
    },
    {
      title: t("ID"),
      dataIndex: "exportId",
      key: "exportId",
      editable: true,
      render: (id) => {  
        if(id) {
            return `${id}`;
        } else {
            return `No ID`;
        }
      },
    }
  ];

  const handleSave = (row: FlatType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    
    setDataSource(newData);
    setChange(row);
    editExportIdValue(row);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: FlatType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        onChange={handleTableChange}
        pagination={{ current: currentPage, pageSize: limit, pageSizeOptions: ["10", "20", "50", "100"], showSizeChanger: true }}
      />
    </div>
  );
};
