import React from "react";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Space,
  Table,
  Upload,
  Radio
} from "antd";
import { usePackages } from "./usePackages";
import { DeleteTwoTone, EditTwoTone, UploadOutlined } from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const Packages = () => {
  const {t} = useTranslation()
  const {
    packages,
    handleUpload,
    uploadProps,
    handleCancel,
    handleCancelEdit,
    isModalVisible,
    isDeleteModalVisible,
    showModal,
    showDeleteModal,
    handleCancelDeletePackage,
    isEditModalVisible,
    showEditModal,
    checkedID,
    deletePackage,
    editPackage,
    setPrice,
    setPackageName,
    packageName,
    price,
    maxUserCount,
    setMaxUserCount,
    isLoading,
    paymentType,
    paymentTypeChange
  } = usePackages();

  const [form] = Form.useForm();

  const columns = [
    {
      title: t("Name"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("Image"),
      key: "imageUrl",
      dataIndex: "imageUrl",
      render: (imageUrl) => <Image width={100} src={`${imageUrl}`} />,
    },
    {
      title: t("PricePerMonth"),
      key: "price",
      dataIndex: "price",
    },
    {
      title: t("MaximumUserCount"),
      key: "maxUserCount",
      dataIndex: "maxUserCount",
    },
    {
      title: t("Period"),
      key: "period",
      dataIndex: "period",
      render: (period) => {
        if (period === 30) {
          return t("ByMonth");
        } else {
          return t("ByYear");
        }
      },
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, maxUserCount) => {
        return (
          <Space size="middle">
            <EditTwoTone
              onClick={() => {
                showEditModal(id);
                setPackageName(maxUserCount.name);
                setMaxUserCount(maxUserCount.maxUserCount);
                setPrice(maxUserCount.price);
              }}
            />
            <DeleteTwoTone
              twoToneColor="red"
              onClick={() => showDeleteModal(id)}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Button onClick={showModal}> {t("AddPackage")} </Button>
      <Divider />
      <Modal
        title={t("AddPackage")}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={handleUpload}>
          <Form.Item label={t("Package")} name="package">
            <Upload
              multiple={false}
              maxCount={1}
              {...uploadProps}
              accept={".png,.jpg,.jpeg"}
            >
              <Button icon={<UploadOutlined />}>{t("UploadPackage")}</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label={t("Name")}
            name="name"
            rules={[
              {
                required: true,
                message: "Please input a name for the package!",
              },
            ]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("PricePerMonth")}
            name="price"
            rules={[
              { required: true, message: "Please input the price per month!" },
            ]}
            preserve
          >
            <Input type={"number"} />
          </Form.Item>
          <Form.Item
            label={t("MaximumUserCount")}
            name="maxUserCount"
            rules={[
              {
                required: true,
                message: "Please input the maximum user count!",
              },
            ]}
            preserve
          >
            <Input type={"number"} />
          </Form.Item>
          <Form.Item label={t("PaymentType")} name="paymentType">
            <Radio.Group onChange={paymentTypeChange} value={paymentType}>
              <Space direction="horizontal">
                <Radio value={'month'}>{t("ByMonth")}</Radio>
                <Radio value={'year'}>{t("ByYear")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("RemovePackageConfirm")}
        visible={isDeleteModalVisible}
        onOk={form.submit}
        onCancel={handleCancelDeletePackage}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Button onClick={() => deletePackage(checkedID)}>{t("Yes")}</Button>
      </Modal>

      <Modal
        title={t("EditPackage")}
        visible={isEditModalVisible}
        onOk={form.submit}
        onCancel={handleCancelEdit}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => editPackage(checkedID)}>
          <Form.Item
            label={t("Name")}
            name="name"
            initialValue={packageName}
            preserve
          >
            <Input
              type="text"
              onChange={(e) => setPackageName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("PricePerMonth")}
            name="price"
            initialValue={price}
            preserve
          >
            <Input type="number" onChange={(e) => setPrice(e.target.value)} />
          </Form.Item>
          <Form.Item
            label={t("MaximumUserCount")}
            name="maxUserCount"
            initialValue={maxUserCount}
            preserve
          >
            <Input
              type="number"
              onChange={(e) => setMaxUserCount(e.target.value)}
            />
          </Form.Item>
          <Form.Item label={t("PaymentType")} name="paymentType">
            <Radio.Group onChange={paymentTypeChange} value={paymentType}>
              <Space direction="horizontal">
                <Radio value={'month'}>{t("ByMonth")}</Radio>
                <Radio value={'year'}>{t("ByYear")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>

      <Table columns={columns} dataSource={packages} loading={isLoading} />
    </div>
  );
};
