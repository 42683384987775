import React from "react";
import {Button, Input, Space, Table} from "antd";
import type { ColumnsType } from "antd/es/table";
import {Link} from "react-router-dom";
import {t} from "i18next";

interface DataType {
    id: string;
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    isActive: boolean;
    avatarUrl: string;
    flatId: string;
    intercomId: string;
    address: string;
    flatNumber: number;
    userSettings: {
        language: string;
        notifications: boolean;
    };
}

const columns: ColumnsType<DataType> = [
    {
        title: "Name",
        dataIndex: "name",
    },
    {
        title: "Phone",
        dataIndex: "phoneNumber",
    },
    {
        title: "Email",
        dataIndex: "email",
    },
    {
        title: "Address",
        dataIndex: "address",
    },
    {
        title: "Flat Number",
        dataIndex: "flatNumber",
    },
    {
        title: t("Action"),
        key: "action",
        dataIndex: "id",
        render: (id, row) => {
            if (!row.intercomId || !row.flatId) {
                return (
                    <Space size="middle">
                        <Link
                            className="disabled-link"
                            to={{ pathname: `#`, state: { id: `${id}` } }}
                        >
                            {t("Details")}
                        </Link>
                    </Space>
                );
            }
            return (
                <Space size="middle">
                    <Link to={{ pathname: `intercoms/${row.intercomId}/flat/${row.flatId}`, state: { id: `${id}` } }}>
                        {t("Details")}
                    </Link>
                </Space>
            );
        },
    }
];

export const UsersList = ({users, setSearchUser, setShowSearchUser}) => {

    const handleChange = (event) => {
        setSearchUser(event.target.value);
    };

    return (
        <div style={{ margin: '0 auto' }}>
            <div style={{display: 'flex'}}>
                <Button onClick={setShowSearchUser} style={buttonStyle}>
                    &lt; Back
                </Button>
                <Input
                    placeholder="Phone number"
                    style={{ marginBottom: '16px' }}
                    onChange={handleChange}
                />
            </div>
            <Table
                columns={columns}
                dataSource={users.map(el => ({ ...el, key: el.id }))}
                pagination={false}
                style={{ width: '100%' }}
            />
        </div>
    )
}

const buttonStyle = {
    background: 'none',
    color: '#2db7f5',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '10px 10px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};