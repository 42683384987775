import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { message } from "antd";

export const useDeviceElevators = () => {
  const [isLoading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [elevators, setElevators] = useState([]);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [showElevatorModal, setShowElevatorModal] = useState<boolean>(false);
  const [elevator, setElevator] = useState([]);
  const [elevatorInfo, setElevatorInfo] = useState({});
  const [isAddElevatorLoading, setAddElevatorLoading] = useState<boolean>(
    false
  );
  const [selectedElevator, setSelectedElevator] = useState<string>("");
  const [isNotificationSending, setIsNotificationSending] = useState(false);
  const [showAddFlats, setShowAddFlats] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<string>("");

  const { authAxios, authAxios2 } = useContext(FetchContext);

  const getData = async () => {
    setLoading(true);
    try {
    const elevatorsData = await authAxios2.get("/admin/elevators");
      if(elevatorsData) {
        const filteredDevices = elevatorsData.data.elevatorsJson.filter((data) => data.address == null);
        setElevators(filteredDevices);
      }
      setLoading(false);
    } catch (e) {
      //console.log('Get Elevator ERROR ==>', e);
      setLoading(false);
      message.error(
          e.response.data.message != null
            ? e.response.data.message
            : "List of Elevator could not be shown!"
        );  
    }
  };

  useEffect(() => {
    getData();
  }, [authAxios2]);

  const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setElevator([]);
      setSelectedElevator(id);
    } else {
      setElevator(row);
      setCheckedIDs([...checkedIDs, id]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setElevator([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(elevators.map((it) => it.id));
    }
  };

  const handleAddElevatorOnClick = () => {
    setShowElevatorModal(true);
  };

  const handleAddElevatorCancel = () => {
    setShowElevatorModal(false);
  };

  const handleAddElevator = async (data: any) => {
    try {
        const response = await authAxios2.post(`/admin/elevators/create`, {
            serial_code: data.serial_number
        });
        if(response) {
            getData();
            setShowElevatorModal(false);
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "Elevator has been successfully added"
            );
        }
    } catch (e) {
        //console.log('ADD ELEVATOR ERROR ==>', e);   
        message.error(
          e.response.data.message != null
            ? e.response.data.message
            : "Elevator could not be added!"
        );  
    }
  };

  const handleAddFlatOnClick = () => {
    setShowAddFlats(true);
  };

  const handleAddFlatCancel = () => {
    setShowAddFlats(false);
  };

  const handleOnSelect = (e) => {
    setSelectedAddress(e.target.value);
  };

  const addFlat = async (data: any) => {
    setLoading(true);
    let flats = [];
    for(let i = Number(data.firstFlat); i <= Number(data.lastFlat); i++) {
      flats.push(Number(i));
    }
    
    try {
        const response = await authAxios2.post(`/admin/elevators/address/add`, {
            address: data.address,
            elevator_id: selectedElevator,
            entrance_number: data.entrance_id,
            flats: flats,
            google_id: data.address
        });
        if(response) { 
            setLoading(false);
            setShowAddFlats(false);
            setSelectedElevator("");
            setCheckedIDs([]);
            setElevator([]);
            getData();
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "Address has been successfully added"
            );
        }
    } catch (e) {
        //console.log('ADD FLAT ERROR ==>', e);  
        setLoading(false);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "Address could not be added!"
          );      
    }
  }; 
  
  const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleDeleteConfirm = async () => { 
    try {
        const response = await authAxios2.delete(`/admin/elevators/${selectedElevator}`);
        if(response) {
            if (response.status === 200) {
                message.success("Device has been successfully deleted!");
            }
            setSelectedElevator("");
            setCheckedIDs([]);
            getData();  
        }
    } catch (e) {
        message.error("There was a problem while deleting the device!");
    }
  };
  
  return {
    elevators: elevators,
    checkedIDs,
    changeCheckedValue,
    changeSelectAll,
    areAllChecked,
    elevator,
    elevatorInfo,
    setSelectedElevator,
    isLoading,
    handleAddElevatorOnClick,
    showElevatorModal,
    handleAddElevatorCancel,
    handleAddElevator,
    isAddElevatorLoading,
    isNotificationSending,
    showAddFlats,
    handleAddFlatOnClick,
    handleAddFlatCancel,
    addFlat,
    handleOnSelect,
    handleCancel,
    handleDeleteConfirm
  };
};
