import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { message, RadioChangeEvent } from "antd";

export const useDevices = () => {
  const [isLoading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [registered, setRegistered] = useState(false);
  const [unregistered, setUnregistered] = useState(false);
  const [checkedID, setCheckedID] = useState("");
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [device, setDevice] = useState([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [vposAccounts, setVposAccounts] = useState<any[]>([]);
  const [secondDoor, setSecondDoor] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [selectedAccount, setSelectedAccount] = useState('');
  const { authAxios } = useContext(FetchContext);

  useEffect(() => {
    getDevices();
  }, [authAxios]);

  const getDevices = async () => {
    setLoading(true);
    const { data } = await authAxios.get("/admin/intercoms");
    const filteredDevices = data.data.filter((data) => data.address == null);
    setDevices(filteredDevices);
    setOriginalList(filteredDevices);
    setLoading(false);
  };

  const getRegistered = () => {
    if (registered) {
      setDevices(originalList);
      setRegistered(false);
      setUnregistered(false);
    }
    if (!registered) {
      const filteredDevices = originalList.filter(
        (data) => data.address != null
      );
      setDevices(filteredDevices);
      setRegistered(true);
      setUnregistered(false);
    }
  };

  const getUnregistered = () => {
    if (!unregistered) {
      const filteredDevices = originalList.filter(
        (data) => data.address == null
      );
      setDevices(filteredDevices);
      setUnregistered(true);
      setRegistered(false);
    }
    if (unregistered) {
      setDevices(originalList);
      setUnregistered(false);
      setRegistered(false);
    }
  };

  const changeCheckedValue = (row, id) => {
    if (checkedID === id) {
      setCheckedID("");
      setDevice([]);
    } else {
      setDevice(row);
      setCheckedID(id);
    }
  };

  const handleSecondDoor = async (value) => {
    try {
      const response = await authAxios.patch(
        `/admin/intercoms/${checkedID}/second-door`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        //console.log('SECOND DOOR STATUS ==>', response);
        return response;
      }
    } catch (error) {
      //console.log('SECOND DOOR STATUS ERROR ==>', error);
      message.error(
        "There was a problem with address update. Please try again later!"
      );
      setModalVisibility(false);
      setSelectedAddress("");
    }
  };

  const handleDeviceInstall = async (values: any) => {
    setLoading(true);
    const updateIntercom = async () => {
      await authAxios
        .put(`/admin/intercoms/${checkedID}`, {
          address: values.address,
          google_id: values.address,
          first_flat: values.firstFlat,
          last_flat: values.lastFlat,
          entrance_id: values.entrance_id,
          vposAccountId: values.vposAccount
        })
        .then(async (response) => {
          if (response.status === 200) {
            const secondDoorResponse = await handleSecondDoor(secondDoor);
            if (secondDoorResponse) {
              message.success("Intercom Has Been Updated Successfully");
            }
          }
        })
        .catch((err) => {
          message.error(
            "There was a problem with address update. Please try again later!"
          );
        });
    };
    await updateIntercom();
    setModalVisibility(false);
    await getDevices();
    setLoading(false);
  };

  const handleOnSelect = (ev) => {
    setSelectedAddress(ev.target.value);
  };

  const handleCancelEdit = () => {
    setSelectedAddress("");
    setAddresses([]);
    setModalVisibility(false);
  };

  const onAccountChange = (id: string) => { 
    setSelectedAccount(id);
  };

  const getVPOSAccounts = async () => {
    try {
      const response = await authAxios.get(`/admin/vpos-account/all`);
      if(response) {
        setVposAccounts(response.data.data.map((el) => ({ ...el, label: el.name, value: el.id })));
      }
    } catch (e) {
      console.log('GET VPOS ACCOUNTS ERROR ==>', e); 
    }
  };

  const showModal = () => {
    setAddresses([]);
    setModalVisibility(true);
    getVPOSAccounts();
  };

  const secondDoorChange = (e: RadioChangeEvent) => {
    setSecondDoor(e.target.value);
  };

  return {
    devices: devices,
    getRegistered,
    getUnregistered,
    changeCheckedValue,
    checkedID,
    device,
    handleDeviceInstall,
    handleOnSelect,
    handleCancelEdit,
    isModalVisible,
    addresses,
    showModal,
    isLoading,
    secondDoor,
    secondDoorChange,
    vposAccounts,
    selectedAccount,
    onAccountChange
  };
};
