import React from "react";
import { useFlatDetailsElevator } from "./useFlatDetailsElevator";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Space,
  Table,
  Switch,
  Checkbox
} from "antd";
import {
  BulbTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import {useTranslation} from "react-i18next";

export const FlatDetailsElevator = () => {
  const {
    flatDetails,
    users,
    payments,
    handleFlatActivationOnClick,
    handleUserActivationOnClick,
    handleDeleteUserFromFlat,
    handleCancelDelete,
    showEditModal,
    handleEditBalanceModal,
    handleCancelEdit,
    setBalance,
    editBalance,
    setPhoneNumber,
    setElevatorCode,
    handleAddUserModal,
    handleCancelAddUser,
    addUser,
    showAddUserModal,
    elevatorCode,
    handleAddNFCForFlatOnClick,
    addNFCModal,
    handleAddNFCModalCancel,
    handleAddNFCForFlat,
    handleNFCDelete,
    handleNFCDeleteCancel,
    NFCs,
    handleNFCActivationOnClick,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete
  } = useFlatDetailsElevator();

  const { confirm } = Modal;
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const handleNFCShowConfirm = (id: any) => {
    confirm({
      title: "Delete NFC Code",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete this NFC Code?",
      onOk() {
        handleDeleteNFCCode(id);
      },
      onCancel() {
        handleCancelNFCCodeDelete();
      },
    });
  };

  const handleShowConfirm = (user: any) => {
    confirm({
      title: "Delete User",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete this user?",
      onOk() {
        handleDeleteUserFromFlat(user);
      },
      onCancel() {
        handleCancelDelete();
      },
    });
  };

  const handleDeleteNFCConfirm = () => {
    confirm({
      title: "Delete NFC for Flat",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete NFC for this flat?",
      onOk() {
        handleNFCDelete();
      },
      onCancel() {
        handleNFCDeleteCancel();
      },
    });
  }; 

  const flatColumns = [
    {
      title: `${t("Flat")} #`,
      dataIndex: "flat_number",
      key: "flat_number ",
      render: (flat_number) => {      
        return <div>{`${flat_number}`}</div>;
      },
    },
    {
      title: t("Package"),
      dataIndex: "active_package_id",
      key: "packageName ",
      render: (active_package) => {
        return <div>{`${active_package && active_package.name ? active_package.name : "NONE"}`}</div>;
      },
    },
    {
      title: t("ValidUntil"),
      dataIndex: "active_until",
      key: "active_until",
      render: (active_until) => {
        if (!active_until) {
          return "NONE";
        }
        return <div>{active_until}</div>;
      },
    },
    {
      title: t("Balance"),
      dataIndex: "balance",
      key: "balance",
      render: (balance) => {
        return (
          <div
            onDoubleClick={() => handleEditBalanceModal()}
          >{`${balance ? balance : 0} AMD`}</div>
        );
      },
    },
    {
      title: t("Active"),
      key: "is_active",
      dataIndex: "is_active",
      render: (is_active) => {
        return (
          <Switch
            checked={is_active}
            onClick={() => handleFlatActivationOnClick(is_active)}
          />
        );
      },
    },
  ];

  const usersColumns = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      render: (name, row) => {    
        if (!name || name === 'Elevator') {
          return "NONE";
        }
        return <div>{`${name} ${row.isAdmin ? "(Admin)" : ""}`}</div>;
      },
    },
    {
      title: t("PhoneNumber"),
      dataIndex: "phoneNumber",
      key: "phone",
      render: (phoneNumber) => {
        if (!phoneNumber) {
          return "NONE";
        }
        return <div>{`${phoneNumber}`}</div>;
      },
    },
    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      render: (email) => {
        if (!email) {
          return "NONE";
        }
        return <div>{`${email}`}</div>;
      },
    },
    {
      title: t("Code"),
      dataIndex: "code",
      key: "code",
      render: (code) => {
        if (!code) {
          return "NONE";
        }
        return (
          <div onDoubleClick={() => handleAddUserModal(code)}>{`${code}`}</div>
        );
      },
    },
    {
      title: t("Admin"),
      key: "admin",
      dataIndex: "isAdmin",
      render: (isAdmin) => {
        if (isAdmin) {
          return (
            <BulbTwoTone
              twoToneColor={isAdmin === true ? "green" : "red"}
              style={{ fontSize: "20px" }}
            />
          );
        }
        return (
          <BulbTwoTone twoToneColor={"red"} style={{ fontSize: "20px" }} />
        );
      },
    },
    {
      title: t("Active"),
      key: "active",
      dataIndex: "is_active",
      render: (is_active, row) => {
        return (
          <Switch
            disabled={!row.user_id}
            checked={is_active}
            onClick={() => handleUserActivationOnClick(is_active, row.user_id)}
          />
        );
      },
    },
    {
      title: t("Delete"),
      key: "id",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <Space size="middle">
            <Button
              danger
              disabled={!row.email}
              onClick={() => handleShowConfirm(row.user_id)}
            >
              {/* {" "}
              {t("Delete")} */}
              <DeleteTwoTone twoToneColor={!row.email ? "grey" : "red"} />
            </Button>
          </Space>
        );
      },
    },
  ];

  const paymentColumns = [
    {
      title: t("PaymentID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("Date"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => {
        return <div>{format(new Date(date), "dd-MMM-yy H:m:s")}</div>;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <div>{`${amount} AMD`}</div>;
      },
    },
    {
      title: t("OrderID"),
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("User"),
      dataIndex: "userName",
      key: "userName",
    },
  ];

  const nfcColumns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("NFCCodes"),
      dataIndex: "nfc_code",
      key: "nfc_code",
    },
    {
      title: t("NFCCodesEnabled"),
      dataIndex: "nfc_code_enabled",
      key: "nfc_code_enabled",
      render: (id, row) => {
        return (
          <Switch
            checked={row.nfc_code_enabled}
            onClick={() => handleNFCActivationOnClick(row)}
          />
        );
      },
    },
    {
      title: t("Delete"),
      key: "id",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <Space size="middle">
            <Button
              danger
              onClick={() => handleNFCShowConfirm(id)}
            >
              <DeleteTwoTone twoToneColor={!id ? "grey" : "red"} />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Divider orientation={"left"}> {t("FlatDetails")} </Divider>
      <Table
        columns={flatColumns}
        dataSource={flatDetails}
        pagination={false}
      />
      <Divider orientation={"left"}> {t("Users")} </Divider>
      <Table columns={usersColumns} dataSource={users} />
      <Divider orientation={"left"}> NFC </Divider>
      <Button onClick={handleAddNFCForFlatOnClick} style={{ marginRight: 8 }}>
        {" "}{t("AddNFCCodes")}{" "}
      </Button>
      <Button onClick={handleDeleteNFCConfirm} style={{ marginRight: 8 }}>
        {" "}{t("DeleteNFC")}{" "}
      </Button>
      <Divider />
      <Table columns={nfcColumns} dataSource={NFCs} />
      <Divider orientation={"left"}> Payments </Divider>
      <Table columns={paymentColumns} dataSource={payments} />
      <Modal
        title={t("EditBalance")}
        open={showEditModal}
        onCancel={handleCancelEdit}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => editBalance()}>
          <Form.Item
            label={t("Balance")}
            name="balance"
            // initialValue={slideName}
            preserve
          >
            <Input type="text" onChange={(e) => setBalance(e.target.value)} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("AddUser")}
        open={showAddUserModal}
        onCancel={handleCancelAddUser}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => addUser()}>
          <Form.Item label={t("FlatCode")}name="code" preserve>
            <Input
              defaultValue={elevatorCode}
              type="text"
              onChange={(e) => setElevatorCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("PhoneNumber")}
            name="number"
            // initialValue={slideName}
            preserve
          >
            <Input
              type="text"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("AddUser")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("AddNFCCodes")}
        open={addNFCModal}
        onOk={form.submit}
        onCancel={handleAddNFCModalCancel}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        //okButtonProps={{ disabled: isEditElevatorLoading }}
        //cancelButtonProps={{ disabled: isEditElevatorLoading }}
      >
        <Form
          form={form}
          //disabled={isEditElevatorLoading}
          onFinish={handleAddNFCForFlat}
        >
          <div style={{display: "flex"}}>
            <Form.Item label={t("NFCCode")} name="nfc_code" rules={[{ required: true, message: "This field is required!" }]}>
                <Input type={"text"} placeholder={t("NFCCodePlaceholder")} style={{ width: 240 }}/>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
