import React from "react";
import { useEffect } from "react";
import {Button, Input, Modal, Form, Table} from "antd";
import type { ColumnsType } from "antd/es/table";
import {t} from "i18next";
import { format } from "date-fns";
import { SendOutlined } from "@ant-design/icons";

export const PopupAlertModal = ({ showPopupAlertModal, handleAddPopupAlertCancel, sendPopupAlert, isNotificationSending, data }) => {
    const [form] = Form.useForm();
    
    const acceptBtn = data.buttons?.find(button => button.action === 'accept');
    const cancelBtn = data.buttons?.find(button => button.action === 'cancel');

    const initialValues = {
        title: data.title || '',
        text: data.text || '',
        positiveBtn: acceptBtn?.text || "Accept",
        negativeBtn: cancelBtn?.text || "Cancel",
        url: data.url || "",
        pictureUrl: data.pictureUrl || "",
        requestUrl: acceptBtn?.requestUrl || ""
    };

    console.log(initialValues);
    useEffect(() => {
        form.setFieldsValue(initialValues);
      }, [initialValues]);

    return (
        <Modal
        title={t("PopupAlert")}
        visible={showPopupAlertModal}
        onOk={form.submit}
        onCancel={handleAddPopupAlertCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} initialValues={initialValues} onFinish={sendPopupAlert}>
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("Text")}
            name="text"
            rules={[{ required: true, message: "Please input the text!" }]}
            preserve
          >
            <Input.TextArea/>
          </Form.Item>

          <Form.Item
            label={t("PositiveButton")}
            name="positiveBtn"
            rules={[{ required: true, message: "Please input the text of the positive button!" }]}
            preserve>
                <Input type={"text"} />
          </Form.Item>
          
          <Form.Item
            label={t("NegativeButton")}
            name="negativeBtn"
            rules={[{ required: true, message: "Please input the text of the negative button!" }]}
            preserve>
                <Input type={"text"} />
          </Form.Item>
          

          <Form.Item
            label={t("Url")}
            name="url"
            rules={[{ required: false }]}
            preserve>
                <Input type={"text"} style={{ marginLeft: "10px"}} />
          </Form.Item>

          <Form.Item
            label={t("ButtonUrl")}
            name="requestUrl"
            rules={[{ required: false }]}
            preserve>
                <Input type={"text"} style={{ marginLeft: "6px"}} />
          </Form.Item>

          <Form.Item
            label={t("PictureUrl")}
            name="pictureUrl"
            rules={[{ required: false }]}
            preserve>
                <Input type={"text"} style={{ marginLeft: "6px"}} />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            loading={isNotificationSending}
            icon={<SendOutlined />}
          >
            {t("Send")}
          </Button>
        </Form>
      </Modal>
    );
}