import React, { useContext } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Table,
} from "antd";
import {
  BulbTwoTone,
  InboxOutlined,
  SendOutlined,
  ThunderboltOutlined,
  PaperClipOutlined,
  HomeOutlined
} from "@ant-design/icons";
import { useElevators } from "./useElevators";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TableSearchInput } from "../../components/TableSearchInput";
import {useTranslation} from "react-i18next";
import { ServicesList } from "./components/ServicesList";

export const Elevators = () => {
  const {
    elevators,
    checkedIDs,
    changeCheckedValue,
    changeSelectAll,
    areAllChecked,
    elevator,
    elevatorInfo,
    setSelectedElevator,
    isLoading,
    handleAddNotificationOnClick,
    showNotificationModal,
    handleAddNotificationCancel,
    addNotifications,
    isNotificationSending,
    handleLinkServiceToIntercoms,
    handleUnlinkServiceFromIntercoms,
    handleAddSliderOnClick,
    handleDeleteSliderOnClick,
    handleAddPackageOnClick,
    handleAddPackageToFlatsOnClick,
    handleDeletePackageOnClick,
    showDeleteSliderModal,
    handleDeleteSliderCancel,
    slidersFromIntercom,
    handleSelectedSlide,
    slideId,
    deleteSlideFromIntercom,
    showSliderModal,
    handleAddSliderCancel,
    slides,
    attachSlide,
    showPackageModal,
    handleAddPackageCancel,
    packages,
    handleSelectedPackage,
    packageId,
    addPackageToIntercom,
    showDeletePackageModal,
    handleDeletePackageCancel,
    packagesFromIntercom,
    deletePackageFromIntercom,
    showPackageToFlatsModal,
    handleAddPackageToFlatsCancel,
    addPackageToAllFlats,
    isLinkServiceModalOpen,
    linkServiceToIntercom,
    toggleLinkServiceModal,
    services,
    setSelectedServices,
    isUnlinkServiceModalOpen,
    unlinkServiceFromIntercom,
    toggleUnlinkServiceModal,
    setFilteredValue,
    filteredValue
  } = useElevators();

  const [form] = Form.useForm();
  const auth = useContext(AuthContext);
  const {t} = useTranslation();

  const columns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              changeCheckedValue(row, id);
              setSelectedElevator(id);
            }}
          />
        );
      },
    },
    {
      title: t("MotherboardID"),
      dataIndex: "serial_code",
      key: "serial_code",
      render: (serial_code) => {
        if (!serial_code) {
          return "NONE";
        }
        return <div>{`${serial_code}`}</div>;
      },
    },
    {
      title: t("ElevatorName"),
      dataIndex: "name",
      key: "name",
      render: (name) => {
        if (!name) {
          return "";
        }
        return <div>{`${name}`}</div>;
      },
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address, row) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address.address}${row.entrance_number ? `, Entrance ${row.entrance_number}` : ''}`}</div>;
      },
      sorter: (a, b) => {
        return a.address.address.localeCompare(b.address.address);
      },
    },
    // {
    //   title: t("SerialNumber"),
    //   dataIndex: "serial_code",
    //   key: "serial_code",
    // },
    {
      title: t("Online"),
      key: "isOnline",
      dataIndex: "isOnline",
      render: (isOnline) => (
        <BulbTwoTone
          twoToneColor={isOnline === true ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, row) => {
        if (!row.address) {
          return (
            <Space size="middle">
              <Link
                className="disabled-link"
                to={{ pathname: `#` }}
              >
                  {t("Details")}
              </Link>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Link
                to={{ pathname: `elevators/${id}` }}
            >
                {t("Details")}
            </Link>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Button
        onClick={handleAddNotificationOnClick}
        disabled={checkedIDs.length !== 1}
        style={{ marginRight: 8 }}
        icon={<SendOutlined />}
      >
          {t("SendNotification")}
      </Button>
      <Button
        onClick={handleLinkServiceToIntercoms}
        disabled={checkedIDs.length === 0}
        style={{ marginRight: 8 }}
        icon={<ThunderboltOutlined />}
      >
          {t("LinkService")}
      </Button>
      <Button
        onClick={handleUnlinkServiceFromIntercoms}
        disabled={checkedIDs.length === 0}
        icon={<ThunderboltOutlined />}
      >
          {t("UnlinkService")}
      </Button>
      <Divider />
      <Button
        onClick={handleAddSliderOnClick}
        disabled={checkedIDs.length === 0}
        icon={<PaperClipOutlined />}
        style={{ marginRight: 8 }}
      >
          {t("AttachSlide")}
      </Button>
      <Button
        onClick={handleDeleteSliderOnClick}
        disabled={checkedIDs.length !== 1}
        danger
      >
          {t("RemoveSlide")}
      </Button>
      <Divider />
       {!auth.isSuperAdmin ? null : (
        <div>
          <Button
            onClick={handleAddPackageOnClick}
            disabled={checkedIDs.length !== 1}
            style={{ marginRight: 8 }}
            icon={<InboxOutlined />}
          >
              {t("AddPackageToIntercom")}
          </Button>
          <Button
            onClick={handleAddPackageToFlatsOnClick}
            disabled={checkedIDs.length !== 1}
            style={{ marginRight: 8 }}
            icon={<HomeOutlined />}
          >
              {t("AddPackageToAllFlats")}
          </Button>
          <Button
            onClick={handleDeletePackageOnClick}
            disabled={checkedIDs.length !== 1}
            danger
          >
              {t("RemovePackage")}
          </Button>
          <Divider />
        </div>
      )}
      <Modal
        title={t("AddNotification")}
        open={showNotificationModal}
        onOk={form.submit}
        onCancel={handleAddNotificationCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addNotifications}>
          <Form.Item
            label={t("Title")}
            name="title"
            rules={[{ required: true, message: "Please input the title!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Form.Item
            label={t("Message")}
            name="body"
            rules={[{ required: true, message: "Please input the message!" }]}
            preserve
          >
            <Input.TextArea />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isNotificationSending}
            icon={<SendOutlined />}
          >
              {t("Send")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("RemoveSlide")}
        open={showDeleteSliderModal}
        onOk={form.submit}
        onCancel={handleDeleteSliderCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {slidersFromIntercom?.map((e) => {
          return (
            slidersFromIntercom?.length && (
              <Button
                key={e.id}
                onClick={() => handleSelectedSlide(e)}
                style={slideId === e.id 
                  ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF"}
                  : { width: "190px", height: "200px", margin: "20px" } 
                }
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={e.imageUrl}
                />
              </Button>
            )
          );
        })}
        <footer>
          <Button
            disabled={!slideId}
            onClick={deleteSlideFromIntercom}
            style={{ backgroundColor: "red", color: "white" }}
          >
              {t("RemoveSlide")}
          </Button>
        </footer>
      </Modal>
      <Modal
        title={t("AttachSlide")}
        open={showSliderModal}
        onOk={form.submit}
        onCancel={handleAddSliderCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {slides?.map((e) => {
          return (
            <Button
              key={e.id}
              onClick={() => handleSelectedSlide(e)}
              style={slideId === e.id 
                ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF"}
                : { width: "190px", height: "200px", margin: "20px" } 
              }
            >
              <img style={{ width: "100%", height: "100%" }} src={e.imageUrl} />
            </Button>
          );
        })}
        <Button disabled={!slideId} onClick={attachSlide}>
            {t("AddSlide")}
        </Button>
      </Modal>
      <Modal
        title={t("AddPackage")}
        open={showPackageModal}
        onOk={form.submit}
        onCancel={handleAddPackageCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {packages?.map((e) => {
          return (
            <Button
              key={e.id}
              onClick={() => handleSelectedPackage(e)}
              style={packageId === e.id 
                ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF"}
                : { width: "190px", height: "200px", margin: "20px" } 
              }
            >
              <img style={{ width: "100%", height: "100%" }} src={e.imageUrl} />
              <div> {e.name} </div>
            </Button>
          );
        })}
        <Button disabled={!packageId} onClick={addPackageToIntercom}>
            {t("AddPackage")}
        </Button>
      </Modal>
      <Modal
        title={t("RemovePackage")}
        open={showDeletePackageModal}
        onOk={form.submit}
        onCancel={handleDeletePackageCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {packagesFromIntercom?.map((e) => {
          return (
            packagesFromIntercom?.length && (
              <Button
                key={e.id}
                onClick={() => handleSelectedPackage(e)}
                style={packageId === e.id 
                  ? { width: "190px", height: "200px", margin: "20px", border: "3px solid #1677FF"}
                  : { width: "190px", height: "200px", margin: "20px" } 
                }
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={e.imageUrl}
                />
                <div> {e.name} </div>
              </Button>
            )
          );
        })}
        <footer>
          <Button
            disabled={!packageId}
            onClick={deletePackageFromIntercom}
            style={{ backgroundColor: "red", color: "white" }}
          >
              {t("RemovePackage")}
          </Button>
        </footer>
      </Modal>
      <Modal
        title={t("AddPackage")}
        open={showPackageToFlatsModal}
        onOk={form.submit}
        onCancel={handleAddPackageToFlatsCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        {packagesFromIntercom?.map((e) => {
          return (
            <Button
              key={e.id}
              onClick={() => handleSelectedPackage(e)}
              style={{ width: "190px", height: "200px", margin: "20px" }}
            >
              <img style={{ width: "100%", height: "100%" }} src={e.imageUrl} />
              <div> {e.name} </div>
            </Button>
          );
        })}
        <Button disabled={!packageId} onClick={addPackageToAllFlats}>
            {t("AddPackage")}
        </Button>
      </Modal>
      <Modal
        title={t("LinkService")}
        open={isLinkServiceModalOpen}
        onOk={linkServiceToIntercom}
        onCancel={() => {
          toggleLinkServiceModal(false);
        }}
        confirmLoading={isLoading}
        okText={t("LinkService")}
        cancelText={t("Cancel")}
      >
        <ServicesList
          services={services}
          setSelectedServices={setSelectedServices}
        />
      </Modal>
      <Modal
        title="Unlink service to intercom (Choose only one)."
        open={isUnlinkServiceModalOpen}
        onOk={unlinkServiceFromIntercom}
        onCancel={() => {
          toggleUnlinkServiceModal(false);
        }}
        confirmLoading={isLoading}
        okText="Unlink from device"
      >
        <ServicesList
          services={services}
          setSelectedServices={setSelectedServices}
        />
      </Modal>
      <TableSearchInput
        setFilterValue={setFilteredValue}
        placeHolder={t("SearchForAddress")}
      />
      <Table
        columns={columns}
        dataSource={elevators.filter((el) =>
          el.address?.address
            ?.toLowerCase()
            .includes(filteredValue.toLowerCase())
        )}
        loading={isLoading}
      />
    </div>
  );
};
