import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Radio,
  Select
} from "antd";
import { useDevices } from "./useDevices";
import { Link } from "react-router-dom";
import { BulbTwoTone } from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const Devices = () => {
  const {
    devices,
    checkedID,
    changeCheckedValue,
    handleDeviceInstall,
    handleOnSelect,
    handleCancelEdit,
    isModalVisible,
    showModal,
    isLoading,
    secondDoor,
    secondDoorChange,
    vposAccounts,
    selectedAccount,
    onAccountChange
  } = useDevices();
  const {t} = useTranslation()

  const [form] = Form.useForm();

  const sortedDevices = devices.sort((a, b) => {
    let address1 = a?.address?.address?.toLowerCase() || "",
      address2 = b?.address?.address?.toLowerCase() || "";

    if (address1 < address2) {
      return -1;
    }
    if (address1 > address2) {
      return 1;
    }
    return 0;
  });

  const columns = [
    {
      dataIndex: "id",
      key: "checkbox",
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedID === id && !row.address}
            disabled={row.address}
            onClick={() => changeCheckedValue(row, id)}
          />
        );
      },
    },
    {
      title: t("MotherboardID"),
      dataIndex: "motherboardId",
      key: "motherboardId",
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return t("NotActivated")
        }
        return <div>{`${address.address}`}</div>;
      },
    },
    {
      title: t("Online"),
      key: "isOnline",
      dataIndex: "isOnline",
      render: (isOnline) => (
        <BulbTwoTone
          twoToneColor={isOnline === "true" ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, row) => {
        if (!row.address) {
          return (
            <Space size="middle">
              <Link
                className="disabled-link"
                to={{ pathname: `#`, state: { id: `${id}` } }}
              >
                {t("Details")}
              </Link>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Link to={{ pathname: `intercoms/${id}`, state: { id: `${id}` } }}>
              {t("Details")}
            </Link>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Divider />
      <Button
        onClick={showModal}
        type="primary"
        disabled={checkedID === "" && !isModalVisible}
        style={{ marginRight: 10 }}
      >
        {"  "}
        {t("AddAddress")}
      </Button>
      <Button
        style={{ marginRight: 8 }}
      >
        {" "}
        <Link to="/devices/elevators">{t("Elevators")}</Link>
      </Button>
      <Divider />
      <Modal
        title={t("InstallDevice")} 
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancelEdit}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={handleDeviceInstall}>
          <Form.Item
            label={t("FirstFlatNumber")}
            name="firstFlat"
            rules={[
              { required: true, message: "Please add flat first flat number!" },
            ]}
            preserve
          >
            <Input type={"number"} style={{ width: 200 }} />
          </Form.Item>
          <Form.Item
            label={t("LastFlatNumber")}
            name="lastFlat"
            rules={[
              { required: true, message: "Please add last flat number!" },
            ]}
            preserve
          >
            <Input type={"number"} style={{ width: 200 }} />
          </Form.Item>

          <Form.Item
            label={t("EntranceNumber")}
            name="entrance_id"
            rules={[{ required: true, message: "Please add entrance number!" }]}
            initialValue={1}
            preserve
          >
            <Input defaultValue={1} type={"number"} style={{ width: 200 }} />
          </Form.Item>
          <Form.Item
            label={t("IntercomAddress")}
            name="address"
            rules={[
              { required: true, message: "Please select valid address!" },
            ]}
            preserve
          >
            <Input
              placeholder={t("DeviceAddress")}
              style={{ width: 300 }}
              onChange={handleOnSelect}
            />
          </Form.Item>
          <Form.Item
            label={t("SecondDoor")}
            name="secondDoor"
            rules={[{ required: true, message: "Please choose an option!" }]}
            preserve
          >
            <Radio.Group onChange={secondDoorChange} value={secondDoor}>
              <Space direction="horizontal">
                <Radio value={true}>{t("Yes")}</Radio>
                <Radio value={false}>{t("No")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={t("vposAccount")}
            name="vposAccount"
            rules={[{ required: true, message: "Please choose an option!" }]}
            preserve
          >
            <Select
              style={{ width: 200, marginRight: 16 }}
              value={selectedAccount}
              onChange={onAccountChange}
              placeholder={t('chooseOption')}
              options={vposAccounts}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("Save")}
          </Button>
        </Form>
      </Modal>

      <Table columns={columns} dataSource={sortedDevices} loading={isLoading} />
    </div>
  );
};
