import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./login.css";
import logo from "../../images/boon_logo.png";
import { useLoginPage } from "./useLoginPage";
import styled from "styled-components";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const WrapperItem = styled.div`
  label{
    color: white !important;
  }
`;
export const Login = (props: any) => {
  const history = useHistory();
  const { onFinish, onFinishFailed, verify, redirectOnLogin } = useLoginPage();
  useEffect(() => {
    if (redirectOnLogin) {
      history.push("/intercoms");
    }
  }, [redirectOnLogin]);
  return (
    <>
      <div className="login-form">
        <div className="form-wrapper">
          <img src={logo} alt="logo" className="login-logo" />
          <Form
            {...layout}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <WrapperItem>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your Username!" },
              ]}
              style={{ color: "white" }}
              className="form-item"
              preserve
              hidden={verify}
            >
              <Input prefix={<MailOutlined />} type="tel" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
              className="form-item"
              preserve
              style={{ color: "white" }}
              hidden={verify}
            >
              <Input.Password prefix={<LockOutlined />} type="password" />
            </Form.Item>
            </WrapperItem>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
