import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";

export const useServices: any = () => {
  const { authAxios } = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  useEffect(() => {
    loadServices();
  }, []);

  const loadServices = async () => {
    setIsLoading(true);
    const { data } = await authAxios.get("/admin/services");
    setServices(data.data);
    setIsLoading(false);
  };

  const createService = async (ev) => {
    setIsLoading(true);
    await authAxios.post(`/admin/services`, ev);
    await loadServices();
  };

  const deleteService = async (serviceId: string) => {
    setIsLoading(true);
    await authAxios.delete(`admin/services/${serviceId}`);
    await loadServices();
  };

  const editService = async (ev, serviceId: string) => {
    setIsLoading(true);
    await authAxios.put(`admin/services/${serviceId}`, ev);
    await loadServices();
  };

  return { isLoading, services, createService, deleteService, editService };
};
