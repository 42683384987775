import React from "react";
import { useState } from "react";
import {Button, Input, Space, Switch, Table} from "antd";
import type { ColumnsType } from "antd/es/table";
import {t} from "i18next";
import { format } from "date-fns";
import { PopupAlertModal } from "./PopupModal";
import { EditTwoTone } from "@ant-design/icons";

interface ButtonType {
    text: string;
    action: string;
    requestUrl: string;
}

interface DataType {
    id: number;
    title: string;
    text: string;
    url: string;
    pictureUrl: string;
    buttons: ButtonType[];
    isActive: boolean;
    createdAt: any;
    updatedAt: any;
}

export const PopupsList = ({popupAlerts, updatePopupAlert, setShowPopupAlertsr, showPopupAlertModal, 
                            handleAddPopupAlertOnClick, handleAddPopupAlertCancel, isNotificationSending}) => {
    const [selectedItem, setSelectedItem] = useState<DataType>({
        id: 0,
        title: "",
        text: "",
        url: null,
        pictureUrl: null,
        buttons: [
            {
                text: "Accept",
                action: "accept",
                requestUrl: null
            },
            {
                text: "Cancel",
                action: "cancel",
                requestUrl: null
            }
        ],
        isActive: false,
        createdAt: null,
        updatedAt: null
    });

    const columns: ColumnsType<DataType> = [
        {
            title: "Pop-up",
            dataIndex: "id",
            render: (id, row) => {
                return (
                    <div style={{ display: "contents", flexDirection: "column", gap: "0px" }}>
                        <h4>{row.title}</h4>
                        <p>{row.text}</p>
                    </div>
                );
            }
        },
        {
            title: t("Date"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date) => {
              return <div>{format(new Date(date), "dd-MMM-yy H:m:s")}</div>;
            },
        },
        {
            title: "Positive Button",
            dataIndex: "positiveBtn",
            render: (id, row) => {
                const acceptBtn = row.buttons?.find(button => button.action === 'accept');

                return (
                    <p>{acceptBtn?.text || "Accept"}</p>
                );
            }
        },
        {
            title: "Negative Button",
            dataIndex: "negativeBtn",
            render: (id, row) => {
                const negativeBtn = row.buttons?.find(button => button.action === 'cancel');

                return (
                    <p>{negativeBtn?.text || "Cancel"}</p>
                );
            }
        },
        {
            title: t("Active"),
            key: "active",
            dataIndex: "id",
            render: (id, row) => (
                <div>
                    <Switch
                        checked={row.isActive}
                        onChange={(checked) => {
                            const acceptBtn = row.buttons?.find(button => button.action === 'accept');
                            const cancelBtn = row.buttons?.find(button => button.action === 'cancel');

                            updatePopupAlert({
                                id: id,
                                title: row.title,
                                text: row.text,
                                url: row.url,
                                pictureUrl: row.pictureUrl,
                                isActive: checked,
                                positiveBtn: acceptBtn?.text || "Accept",
                                negativeBtn: cancelBtn?.text || "Cancel",
                                requestUrl: acceptBtn?.requestUrl || ""
                            });
                        }}
                    />
                </div>
            ),
        },
        {
            title: t("Action"),
            key: "action",
            dataIndex: "id",
            render: (id, row) =>
                <Space size="middle">
                    <EditTwoTone onClick={() => editPopupAlert(row)} 
                    />
                </Space>
          }
    ];

    const editPopupAlert = (value: DataType) => {
        setSelectedItem(value);
        handleAddPopupAlertOnClick();
    };

    return (
        <div style={{ margin: '0 auto' }}>
            <div style={{display: 'flex', marginBottom: '16px'}}>
                <Button onClick={setShowPopupAlertsr} style={buttonStyle}>
                    &lt; Back
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={popupAlerts.map(el => ({ ...el, key: el.id }))}
                pagination={false}
                style={{ width: '100%' }}
            />

            <PopupAlertModal
                showPopupAlertModal={showPopupAlertModal}
                handleAddPopupAlertCancel={handleAddPopupAlertCancel}
                sendPopupAlert={(values: any) => updatePopupAlert({
                    id: selectedItem.id || 0,
                    title: values.title,
                    text: values.text,
                    url: values.url,
                    pictureUrl: values.pictureUrl,
                    isActive: selectedItem.isActive,
                    positiveBtn: values.positiveBtn,
                    negativeBtn: values.negativeBtn,
                    requestUrl: values.requestUrl
                })}
                isNotificationSending={isNotificationSending}
                data={selectedItem}
            />
        </div>
    )
}

const buttonStyle = {
    background: 'none',
    color: '#2db7f5',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '10px 10px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};