import React, { useContext } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Table,
} from "antd";
import {
  BulbTwoTone,
  InboxOutlined,
  ExclamationCircleOutlined,
  SendOutlined
} from "@ant-design/icons";
import { useDeviceElevators } from "./useElevators";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TableSearchInput } from "../../components/TableSearchInput";
import {useTranslation} from "react-i18next";

export const DeviceElevators = () => {
  const {
    elevators,
    checkedIDs,
    changeCheckedValue,
    changeSelectAll,
    areAllChecked,
    elevator,
    elevatorInfo,
    setSelectedElevator,
    isLoading,
    handleAddElevatorOnClick,
    showElevatorModal,
    handleAddElevatorCancel,
    handleAddElevator,
    isAddElevatorLoading,
    isNotificationSending,
    showAddFlats,
    handleAddFlatOnClick,
    handleAddFlatCancel,
    addFlat,
    handleOnSelect,
    handleCancel,
    handleDeleteConfirm
  } = useDeviceElevators();

  const {confirm} = Modal;
  const [form] = Form.useForm();
  const auth = useContext(AuthContext);
  const {t} = useTranslation();

  const handleDeleteElevatorOnClick = () => {
    confirm({
        title: t("DeleteElevatorDevice"),
        icon: <ExclamationCircleOutlined/>,
        content: t("DeleteElevatorConfirm"),
        cancelText: t("Cancel"),
        onOk() {
            handleDeleteConfirm();
        },
        onCancel() {
            handleCancel();
        },
    });
  };
  
  const columns = [
    {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {
        return (
          <Checkbox
            value={id}
            checked={checkedIDs.includes(id)}
            onClick={() => {
              changeCheckedValue(row, id);
              setSelectedElevator(id);
            }}
          />
        );
      },
    },
    {
      title: t("MotherboardID"),
      dataIndex: "serial_code",
      key: "serial_code",
      render: (serial_code) => {
        if (!serial_code) {
          return "NONE";
        }
        return <div>{`${serial_code}`}</div>;
      },
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address, row) => {
        if (!address) {
          return t("NotActivated")
        }
        return <div>{`${address.address}, Entrance ${row.entrance_number}`}</div>;
      },
    },
    {
      title: t("Online"),
      key: "isOnline",
      dataIndex: "isOnline",
      render: (isOnline) => (
        <BulbTwoTone
          twoToneColor={isOnline === true ? "green" : "red"}
          style={{ fontSize: "20px" }}
        />
      ),
    },
    {
      title: t("Action"),
      key: "action",
      dataIndex: "id",
      render: (id, row) => {           
         if (!row.address) {
          return (
            <Space size="middle">
              <Link
                className="disabled-link"
                to={{ pathname: `#`, state: { id: `${id}` } }}
              >
                {t("Details")}
              </Link>
            </Space>
          );
        }
        return (
          <Space size="middle">
            <Link to={{ pathname: `intercoms/${id}`, state: { id: `${id}` } }}>
              {t("Details")}
            </Link>
          </Space>
        );
      },
    },
  ];
 
  return (
    <div> 
      <Button
        onClick={handleAddElevatorOnClick}
        style={{ marginRight: 8 }}
        icon={<InboxOutlined />}
      >
        {t("AddElevator")}
      </Button>
      <Button
        onClick={handleAddFlatOnClick}
        style={{ marginRight: 8 }}
        icon={<InboxOutlined />}
        disabled={checkedIDs.length !== 1 && !showAddFlats}
      >
        {t("AddAddress")}
      </Button>
      <Button
        onClick={handleDeleteElevatorOnClick}
        style={{ marginRight: 8 }}
        disabled={checkedIDs.length !== 1}
        danger
      >
        {t("DeleteDevice")}
      </Button>
      <Divider />

      <Modal
        title={t("AddElevator")}
        open={showElevatorModal}
        onOk={form.submit}
        onCancel={handleAddElevatorCancel}
        cancelText={t("Cancel")}
        style={{ pointerEvents: "none" }}
        okButtonProps={{ disabled: isAddElevatorLoading }}
        cancelButtonProps={{ disabled: isAddElevatorLoading }}
      >
       <Form
        form={form}
        disabled={isAddElevatorLoading}
        onFinish={handleAddElevator}
        initialValues={{
            serial_number: '',
        }}
       >
        <Form.Item label={t("SerialNumber")} name="serial_number" rules={[{ required: true, message: "This field is required!" }]}>
            <Input type={"text"} placeholder={t("ElevatorSerialNumberPlaceholder")}/>
        </Form.Item>
       </Form>
      </Modal>
      <Modal
        title={t("AddAddress")}
        open={showAddFlats}
        onOk={form.submit}
        onCancel={handleAddFlatCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addFlat}>
           <Form.Item
            label={t("FirstFlatNumber")}
            name="firstFlat"
            rules={[
              { required: true, message: "Please add flat first flat number!" },
            ]}
            preserve
          >
            <Input type={"number"} style={{ width: 200 }} />
          </Form.Item>
          <Form.Item
            label={t("LastFlatNumber")}
            name="lastFlat"
            rules={[
              { required: true, message: "Please add last flat number!" },
            ]}
            preserve
          >
            <Input type={"number"} style={{ width: 200 }} />
          </Form.Item>

          <Form.Item
            label={t("EntranceNumber")}
            name="entrance_id"
            rules={[{ required: true, message: "Please add entrance number!" }]}
            initialValue={1}
            preserve
          >
            <Input defaultValue={1} type={"number"} style={{ width: 200 }} />
          </Form.Item>
          <Form.Item
            label={t("ElevatorAddress")}
            name="address"
            rules={[
              { required: true, message: "Please select valid address!" },
            ]}
            preserve
          >
            <Input
              placeholder={t("DeviceAddress")}
              style={{ width: 300 }}
              onChange={handleOnSelect}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      {/* <TableSearchInput
        //setFilterValue={setFilteredValue}
        placeHolder={t("SearchForAddress")}
      /> */}
      <Table
        columns={columns}
        dataSource={elevators}
        loading={isLoading}
      />
    </div>
  );
};
