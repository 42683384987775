import { useState } from "react";
export const useCurrentUser = () => {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleConfirm = () => {
    setShowConfirm(false);
    localStorage.clear();
    window.location.href = "/";
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };
  const handleShowConfirm = () => {
    setShowConfirm(true);
  };
  return {
    showConfirm,
    handleCancel,
    handleConfirm,
    handleShowConfirm,
  };
};
