import React, {useContext, useEffect, useRef, useState} from 'react';
import type {InputRef} from 'antd';
import {Button, Form, Input, Popconfirm, Table, Checkbox, Modal, Radio, Space} from 'antd';
import type {FormInstance} from 'antd/es/form';
import {useTranslation} from "react-i18next";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  total: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleEdit: (record: Item, total: number) => void;
  handleSave: (record: Item) => void;
  handleRefund: (record: String) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  total,
  children,
  dataIndex,
  record,
  handleEdit,
  handleSave,
  handleRefund,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [editingTotal, setEditingTotal] = useState(false);
  const [totalPr, setTotalPr] = useState(0);
  const inputRef = useRef<InputRef>(null);
  const totalRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing && !record["charged"]) {
            inputRef.current!.focus();
        }
        if(editingTotal) {
            totalRef.current.focus()
        }
    }, [editing, editingTotal]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const toggleEditTotal = () => {
    setEditingTotal(!editingTotal);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const editTotalFunc = async () => {
    try {
      const values = await form.validateFields();
      toggleEditTotal();
      handleEdit({ ...record, ...values }, totalPr);
    } catch (err) {
      console.log(err);
    }
  };

  let childNode = children;
  

    if (editable) {
        childNode = editing && !record["charged"] ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : total ? !editingTotal ?
            (<div
            style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
                <p>
                {children}
                </p>
                {record["charged"] ?
                  <button
                    type="button"
                    className="ant-btn css-dev-only-do-not-override-1i536d8 ant-btn-default"
                    onClick={() => handleRefund(record["key"])}>
                      Refund
                  </button>
                  :
                  <button
                      type="button"
                      className="ant-btn css-dev-only-do-not-override-1i536d8 ant-btn-default"
                      disabled={record["charged"]}
                      onClick={toggleEditTotal}>
                      Edit
                  </button>
                }
            </div>) :
                (<div>
                    <Input
                        onChange={(e) => {
                            setTotalPr(+e.target.value)
                        }}
                        ref={totalRef}
                        onBlur={toggleEditTotal}
                        onPressEnter={editTotalFunc}/>
                </div>)
            :
        (
            <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
                {children}
            </div>
        )
    }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface FlatType {
    key: React.Key;
    flatNumber: string;
    days: number;
    serviceValue: number;
    totalAmount: number;
    charged: boolean;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const ServiceTable: React.FC<{ flats: any[], addServiceValues: any, selectedService: any,serviceFlatValues:any,services:any,editTotal:any,handleTableChange: any,currentPage: any,limit: any,areAllChecked:any,checkedIDs:any[],changeSelectAll:any,changeCheckedValue:any,setSelectedFlat:any,fixedPriceModal:any, handleFixedPriceCancel: any, unitValueType: any, unitValueTypeChange: any, setFixedPriceModal: any, currentMonth: any, handleRefund: any, days: any, daysNumberChange: any}> = ({flats,addServiceValues,serviceFlatValues,selectedService,services, editTotal, handleTableChange, currentPage, limit, areAllChecked, checkedIDs, changeSelectAll, changeCheckedValue, setSelectedFlat, fixedPriceModal, handleFixedPriceCancel, unitValueType, unitValueTypeChange, setFixedPriceModal, currentMonth, handleRefund, days, daysNumberChange}) => {
    const [form] = Form.useForm();
    const modalRef = useRef(null);
    const {t} = useTranslation();
    const [dataSource, setDataSource] = useState<FlatType[]>(flats);
    const [currentServiceInfo, setCurrentServiceInfo] = useState({
        price: 0,
        unitName: "",
        priceType: ""
    });
    const [change, setChange] = useState(null);
    useEffect(()=> {
        services.forEach(el=>{
            if(el.id===selectedService){
                setCurrentServiceInfo(el)
            }
        })
    },[selectedService, services])

    useEffect(()=> {
        if(serviceFlatValues[selectedService]) {
            const valueFlats = serviceFlatValues[selectedService] || []
            
            const flatValuesObj = valueFlats.reduce((obj, item) => {
                obj[item.flatNumber] = item;
                return obj;
            }, {});

            const newFlats = flats.map(el => {
                let totalAmount = 0;

                if (currentServiceInfo.priceType === "flexible_days") {
                    totalAmount = flatValuesObj[el.flatNumber]?.unitValue * currentServiceInfo?.price * (flatValuesObj[el.flatNumber] && flatValuesObj[el.flatNumber].days && currentServiceInfo && currentServiceInfo.priceType && currentServiceInfo.priceType === "flexible_days" ? flatValuesObj[el.flatNumber].days : 0) || 0;
                } else {
                    totalAmount = flatValuesObj[el.flatNumber]?.unitValue * currentServiceInfo?.price || 0;
                }
                return {
                    key: el.id,
                    flatNumber: el.flatNumber,
                    serviceValue: flatValuesObj[el.flatNumber] ? flatValuesObj[el.flatNumber]?.unitValue : 0,
                    totalAmount: totalAmount,
                    charged: flatValuesObj[el.flatNumber] ? flatValuesObj[el.flatNumber]?.charged : false,
                    days: flatValuesObj[el.flatNumber] ? flatValuesObj[el.flatNumber]?.days : 0
                }
            })
            setDataSource(newFlats)
        }
    },[flats, serviceFlatValues,selectedService]); 

   const otherTypesColumn = [
     {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {    
        return (
          <Checkbox
            value={row["key"]}
            checked={checkedIDs.includes(row["key"])}
            // disabled={row["charged"]}
            onClick={() => {
              changeCheckedValue(row, row["key"]);
              setSelectedFlat(row["key"]);
            }}
          />
        );
      },
    },
    {
      title: "Flat Number",
      dataIndex: "flatNumber",
      width: "20%",
      key: "flatNumber",
    },
    {
      title: `Unit value (${currentServiceInfo?.unitName})`,
      dataIndex: "serviceValue",
      width: "40%",
      editable: true,
    },
    {
      title: `Total price (${currentServiceInfo?.price} x Unit Value)`,
      dataIndex: "totalAmount",
      width: "40%",
      editable: true,
      total: true,
      refund: true,
      render: (totalAmount) => { 
        return <div>{`${Math.ceil(totalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</div>;
      },
    },
  ];
    
  const flexibleDaysColumn = [
     {
      dataIndex: "id",
      key: "checkbox",
      title: (
        <Checkbox onClick={() => changeSelectAll()} checked={areAllChecked} />
      ),
      render: (id, row) => {    
        return (
          <Checkbox
            value={row["key"]}
            checked={checkedIDs.includes(row["key"])}
            // disabled={row["charged"]}
            onClick={() => {
              changeCheckedValue(row, row["key"]);
              setSelectedFlat(row["key"]);
            }}
          />
        );
      },
    },
    {
      title: "Flat Number",
      dataIndex: "flatNumber",
      width: "20%",
      key: "flatNumber",
    },
    {
      title: "Days",
      dataIndex: "days",
      width: "20%",
      key: "days",
      editable: true,
      render: (days, row) => {
        if(days) {
          return <div>{days}</div>
        } else {
          return 0;
        }
      },
    },
    {
      title: `Unit value (${currentServiceInfo?.unitName})`,
      dataIndex: "serviceValue",
      width: "40%",
      editable: true,
    },
    {
      title: `Total price (${currentServiceInfo?.price} x Unit Value)`,
      dataIndex: "totalAmount",
      width: "40%",
      editable: true,
      total: true,
      refund: true,
      render: (totalAmount) => { 
        return <div>{`${Math.ceil(totalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</div>;
      },
    },
  ];  

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    total?: boolean;
    refund?: boolean;
    dataIndex: string;
  })[] = currentServiceInfo && currentServiceInfo.priceType && currentServiceInfo.priceType === "flexible_days" ? flexibleDaysColumn : otherTypesColumn;

  const monthNames = [t("January"), t("February"), t("March"), t("April"), t("May"), t("June"),
      t("July"), t("August"), t("September"), t("October"), t("November"), t("December")
    ];

  const handleUnitValueModal = () => {
    setFixedPriceModal(true);
  };

  const handleSave = (row: FlatType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      totalAmount: Math.ceil(row.serviceValue * currentServiceInfo.price),
    });

    daysNumberChange({ target: {
            value: row.days
        }
    });

    if (currentServiceInfo.priceType === "flexible_days") {
        if (row.days != 0 && row.serviceValue != 0) {
            handleUnitValueModal();
        } else {
            addServiceValues(row);
        }

        setDataSource(newData);
        setChange(row);
    } else {
        row.days = null;
        setDataSource(newData);
        handleUnitValueModal();
        setChange(row);
    }
    //addServiceValues(row);
  };

  const handleEdit = (row: FlatType, total: number) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      totalAmount: Math.ceil(total),
    });
    setDataSource(newData);
    editTotal(row, total.toString());
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: FlatType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        total: col.total,
        handleSave,
        handleEdit,
        handleRefund
      }),
    };
  });

  useEffect(() => {
    if(fixedPriceModal) {    
      modalRef.current!.focus();
    }
  }, [fixedPriceModal]);

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        onChange={handleTableChange}
        pagination={{ current: currentPage, pageSize: limit, pageSizeOptions: ["10", "20", "50", "100"], showSizeChanger: true }}
      />
      <div 
        ref={modalRef}
        onKeyUp={(e) => {
          if (e.key === "Enter") { 
            form.submit();
          }
        }}
      >
        <Modal
          title={`${t("EditUnitValue")}`}
          open={fixedPriceModal}
          onOk={form.submit}
          onCancel={handleFixedPriceCancel}
          okText={`${t("Save")}`}
          cancelText={`${t("Cancel")}`}
        >
            <Form 
              form={form} 
              onFinish={() => addServiceValues(change)} 
            >
              <p>{`${t("unitValueQuestion1")} ${monthNames[new Date(currentMonth.date).getMonth()]} ${t("unitValueQuestion2")}`}</p>
              <Form.Item name="unitValueType">
                  <Radio.Group onChange={unitValueTypeChange} value={unitValueType} defaultValue={unitValueType}>
                    <Space direction="horizontal">
                      <Radio value={false}>{t("onlyThisMonth")} {`${monthNames[new Date(currentMonth.date).getMonth()]}`}</Radio>
                      <Radio value={true}>{t("remainingMonths")}</Radio>
                    </Space>
                  </Radio.Group>
              </Form.Item>
            </Form>
        </Modal>
      </div>
    </div>
  );
};
