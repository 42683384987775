import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { AuthContext } from "../../context/AuthContext";
import { useParams, useLocation } from "react-router-dom";
import { DatePickerProps, message, RadioChangeEvent } from "antd";
import dayjs from "dayjs";

export const ALL_FLATS = "All Flats";
export const useEditElevator = () => {
  // const {state} = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [elevatorId, setElevatorId] = useState(useParams);
  // const [elevatorAddressId, setElevatorAddressId] = useState(state);
  const [elevatorDetails, setElevatorDetails] = useState<any[]>([]);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [addNFCModal, setAddNFCModal] = useState<boolean>(false);
  const [isEditElevatorLoading, setEditElevatorLoading] = useState<boolean>(
    false
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [flats, setFlats] = useState<any[]>([]);
  const [flatsUser, setFlatsUser] = useState<any[]>([]);
  const [pinCode, setPinCode] = useState(null);
  const [pinCodes, setPinCodes] = useState(null);
  const [guestPins, setGuestPins] = useState([]);
  const [selectedPinCodes, setSelectedPinCodes] = useState(null);
  const [selectedGuestPinCodes, setSelectedGuestPinCodes] = useState(null);
  const [showAddFlats, setShowAddFlats] = useState(false);
  const [active, setActive] = useState<boolean>(false);
  const [NFCUploadModal, setNFCUploadModal] = useState<boolean>(false);
  const [selectedFlat, setSelectedFlat] = useState<any>([]);
  const [selectedFlatUsersCount, setSelectedFlatUsersCount] = useState(null);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [isSortedByBalance, setIsSortedByBalance] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(
    false
  );
  const [isNotificationSending, setIsNotificationSending] = useState(false);
  const [showAddUserCodeModal, setShowAddUserCodeModal] = useState(false);
  const [forceOpenModal, setForceOpenModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [showConnectElevators, setShowConnectElevators] = useState<boolean>(false);
  const [elevatorsData, setElevatorsData] = useState<any[]>([]);
   const [filteredValue, setFilteredValue] = useState<string>("");

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const fileReader = new FileReader();
  const { authAxios, authAxios2 } = useContext(FetchContext);
  const auth = useContext(AuthContext);

  const getElevatorsList = async () => {
    const response = await authAxios2.get(auth.authState.userInfo.isSuperAdmin ? "/admin/elevators" : `/admin/elevators/user/${auth.authState.userInfo.id}`);
    if(response) {
      setElevatorsData(response.data.elevatorsJson.filter((item) => !!item.address));
    }
  };

  const getElevatorDetails = async () => {
    try {
      const elevatorsData = await authAxios2.get("/admin/elevators");
      if(elevatorsData) {
        //console.log('ElevatorsData ==>', elevatorsData);
        const filteredDevices = elevatorsData.data.elevatorsJson.filter((data) => data.id == elevatorId.id);
        setElevatorDetails(filteredDevices);
      }
    } catch (e) {
      console.log('ELEVATORS ERROR ==>', e);
      
    }
  };

  const getElevatorStatus = async () => {
    try {
      const response = await authAxios2.get(`/admin/elevator/status/${elevatorId.id}`);
      if(response) {
        //console.log('ELEVATOR STATUS ==>', response.data);
        return response.data.isOnline;
      }
    } catch (e) {
      console.log('ELEVATOR STATUS ERROR ==>', e);
      return false;
    }
  };

  const getFlats = async () => {
    setLoading(true);
    try {
        const flatsData = await authAxios2.get(`/admin/flats/elevators/${elevatorId.id}`);
        if(flatsData) {
          let elevatorInfo = flatsData.data.elevator;
          const status = await getElevatorStatus();
          Object.assign(elevatorInfo, { isOnline: status});
          setElevatorDetails([elevatorInfo]);
          setFlats(flatsData.data.elevator.flats);
          setFlatsUser(flatsData.data.elevator.flatsUser);
          let pin = flatsData.data.elevator.pins.reduce((a, b) => {
            return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
          }); 
          if(!pin.is_guest) {
            setPinCode(
              {
                title: pin.pin_code,
                label: pin.pin_code,
                value: pin.pin_code,
                id: pin.id
              }
            );
          }
          let codes = [];
          let guestCodes = [];
          flatsData.data.elevator.pins.map((code) => {
            if(code.is_guest) {
                guestCodes.push({
                    title: code.pin_code,
                    label: code.pin_code,
                    value: code.pin_code,
                    id: code.id
                });
            } else {
                codes.push({
                    title: code.pin_code,
                    label: code.pin_code,
                    value: code.pin_code,
                    id: code.id
                });
            }
            return codes;
          });
          if(codes.length) {
            setPinCodes(codes);
          }
          if(guestCodes.length) {
              setGuestPins(guestCodes);
          }
          setLoading(false);
          return flatsData.data;
        }
    } catch (e) {
        //console.log('GET FLATS ERROR ==>', e);
        setLoading(false);       
    }
  };


  useEffect(() => {
    getFlats();
  }, [authAxios2]);

  const showModal = () => {
    //setAddresses([]);
    setModalVisibility(true);
  };

  const handleCancelEdit = () => {
    //setSelectedAddress("");
    //setAddresses([]);
    setModalVisibility(false);
  };

  const onPinCodeChange = (code, data, isAvailable) => {
      if (isAvailable) {
          setSelectedPinCodes(data);
      } else {
          setSelectedGuestPinCodes(data);
      }
  };

  const handleDeletePinCodes = async (pinCodes) => {
      const results = await Promise.all(pinCodes.map(code => deletePinCode(code.id)));
      const allDeleted = results.every(result => result === true);

      if (allDeleted) {
          message.success("Selected permanent codes are successfully deleted!");
      } else {
          message.error("There was a problem while editing the Permanent Code!");
      }
      setSelectedPinCodes(null);
      setSelectedGuestPinCodes(null);
      getElevatorDetails();
      getFlats();
  };

  const deletePinCode = async (pinCodeId) => {
    try {
      const response = await authAxios2.delete(`/admin/pin/${pinCodeId}`);
      if(response) {
        console.log('DELETE Pin Code response ==>', response); 
        return true;
      }
    } catch (e) {
      console.log('Delete Pin Code ERROR ==>', e);
    }
    return false;
  };

  const handlePinCode =  async (code) => {
    try {
      const response = await authAxios2.post(`/admin/elevators/pin/create`, {
        add_manually: true,
        pin_codes: [
          {
            flat_id: flats[0].id.toString(),
            elevator_id: Number(elevatorId.id),
            pin_code: code,
            pin_code_enabled: true,
            is_guest: false,
            living: 0
          },
        ]
      });
      if(response) {
        console.log('Pin Code create ==>', response);
        return response;
      }
    } catch (e) {
      console.log('Pin Code create ERROR ==>', e);
      message.error("There was a problem while editing the elevator!");
    }
  };

   const handleElevatorEdit = async (editData) => {
       setSelectedPinCodes(null);
       setSelectedGuestPinCodes(null);
       let data = {};
       if (elevatorDetails[0]?.name !== editData.elevator_name) {
           data[`name`] = editData.elevator_name;
       }
       if (elevatorDetails[0].address.address !== editData.address) {
           data[`address`] = editData.address;
       }
       if (editData.entrance !== '') {
           data[`entrance`] = Number(editData.entrance);
       }

       try {
           const response = await authAxios2.put(`/admin/elevator/address/${elevatorId.id}`, data);
           if (isValidPermanentCode(editData.permanent_code)) {
               // const response = await deletePinCode();
               // if(response) {
               // }
               await handlePinCode(editData.permanent_code);
           }
           // else if(!pinCode && editData.permanent_code) {
           //   await handlePinCode(editData.permanent_code);
           // }

           if (response) {
               if (response.status === 200) {
                   message.success("Elevator has been successfully updated!");
                   setModalVisibility(false);
                   getElevatorDetails();
               }
           }
       } catch (e) {
           message.error("There was a problem while editing the elevator!");
       }
   };

   const deleteElevator = async () => {
        try {
            const response = await authAxios2.delete(`/admin/elevators/${elevatorId.id}`);
            if(response) {
                if (response.status === 200) {
                    message.success("Elevator has been successfully deleted!");
                }  
            }
        } catch (e) {
            message.error("There was a problem while deleting the elevator!");
        }
  };

   const handleConfirm = () => {
    deleteElevator()
      .then(() => {
        window.location.href = "/elevators";
      })
      .catch((err) => {
        message.error("There was a problem while deleting the elevator!");
      });
  };

  const handleFlatConfirm = async (id: any) => {
    try { 
      const response = await authAxios2.delete(`/admin/flats/${id}`);
      if(response) {
        //console.log('Delete Flat RESPONSE ==>', response);
        getFlats();
        message.success("Flat has been successfully deleted!"); 
      }
    } catch (e) {
      //console.log('Delete Flat ERROR ==>', e);
      message.error("There was a problem while deleting the flat!");
    }
  };

   const handleCancel = () => {
    setShowConfirm(false);
  };

  const handleAddFlatOnClick = () => {
    setShowAddFlats(true);
  };

  const handleAddFlatCancel = () => {
    setShowAddFlats(false);
  };

  const addFlat = async (data: any) => {
    setLoading(true);
    let step = 1;
    let flats = [];
    if(data.lastFlat) {
      flats = Array.from(
        { length: (Number(data.lastFlat) - Number(data.firstFlat)) / step + 1 },
        (value, index) => Number(data.firstFlat) + index * step
      );
    } else {
      flats.push(Number(data.firstFlat));
    }
    
    try {
        const response = await authAxios2.post(`/admin/elevators/flats/add`, {
          elevator_id: elevatorId.id,
          flats: flats
        });
        if(response) {
            setShowAddFlats(false);
            setLoading(false);
            getFlats();
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "Flats has been successfully added"
            );
        }
    } catch (e) {
        //console.log('ADD FLAT ERROR ==>', e);  
        setLoading(false);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "Flats could not be added!"
          );      
    }
  };   

  const handleAddNFCsOnClick = () => {
    setNFCUploadModal(true);
  };

  const handleAddNFCsCancel = () => {
    setNFCUploadModal(false);
  };

  const enableNFCforFlat = async (flatID: string) => {
    try {
        const response = await authAxios2.put(`/admin/flat/nfc/enable/${flatID}`);
        if(response) {
            setActive(true);
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "NFC has been successfully enabled"
            );
        }
    } catch (e) {
        //console.log('ENABLE NFC ERROR ==>', e);
         message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be enabled!"
          );    
    }
  };

  const disableNFCforFlat = async (flatID: string) => {
    try {
        const response = await authAxios2.put(`/admin/flat/nfc/disable/${flatID}`);
        if(response) {
            setActive(false);
             message.success(
              response?.data?.message != null
                ? response.data.message
                : "NFC has been successfully disabled"
            );
        }
    } catch (e) {
        //console.log('DISABLE NFC ERROR ==>', e);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be disabled!"
          );
    }
  };

  const handleNFCActivationOnClick = (flatID: string) => {
    if(active) {
        disableNFCforFlat(flatID);
    } else {
        enableNFCforFlat(flatID);
    }
  };

  const handleAddNFCForFlatOnClick = () => {
    setAddNFCModal(true);
  };

  const handleAddNFCModalCancel = () => {
    setAddNFCModal(false);
  };

  const handleAddNFCForFlat = async (data: any) => {
    try {
        const response = await authAxios2.post(`/admin/elevators/nfc/create`, {
            add_manually: true,
            nfc_codes: [
                {
                    flat_id: data.flat_id,
                    elevator_id: elevatorId.id,
                    nfc_code: data.nfc_code,
                    nfc_code_enabled: true
                }
            ]
        });
        if(response) {
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "NFC has been successfully added!"
            );
        }
    } catch (e) {
        //console.log('ADD NFC for FLAT ERROR ==>', e);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "NFC could not be added!"
          );
    }
  };

   const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

  const uploadNFCFile = async () => {   
    const formData = new FormData();
    formData.append('csvFile', file);
    formData.append('elevatorId', elevatorId.id);
    formData.append('flatId', selectedFlat);

    try {
        const response = await authAxios2.post(`/admin/elevators/import/nfc`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        });

        if(response) {
            message.success(
              response?.data?.message != null
                ? response.data.message
                : "File has been successfully uploaded!"
            );
            setNFCUploadModal(false);
            
        }
    } catch (e) {
        //console.log('NFC Codes file upload ERROR ==>', e);
        setNFCUploadModal(false);
        message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "File could not be uploaded!"
          );
    }
  };

   const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setSelectedFlat(id);
    } else {
      setCheckedIDs([...checkedIDs, id]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(flats.map((it) => it.id));
    }
  };

  const handleDetailsOnClick = (flat: any) => {
    setSelectedFlat(flat);
    //window.location.href = `/elevators/${elevatorId.id}/flat/${flat.id}`;
  };

  const handleAddNotificationOnClick = () => {
    setShowNotificationModal(true);
  };

  const handleAddNotificationCancel = () => {
    setShowNotificationModal(false);
  };

  const addNotifications = async (values: any) => {
    const addNotification = async () => {
      setIsNotificationSending(true);
      await authAxios
        .post(`/notifications/flats`, {
          flatIds: checkedIDs,
          title: values.title,
          body: values.body,
        })
        .then((response) => {
          if (response) {
            setShowNotificationModal(false);
            message.success(
              response.data.message != null &&
                response.data.message === "notification.sent"
                ? "Notification has been successfully added"
                : response.data.message
            );
            setIsNotificationSending(false);
          }
          setAreAllChecked(false);
          setCheckedIDs([]);
        })
        .catch((err) => {
          message.error(
            err.response.data.message != null
              ? err.response.data.message
              : "Notification could not be added!"
          );
          setIsNotificationSending(false);
        });
    };
    addNotification();
  };

  const handleAddUserCodeModal = (id, count) => {
    setShowAddUserCodeModal(true);
    setSelectedFlat(id);
    setSelectedFlatUsersCount(count);
  };

  const handleCancelAddUserCode = () => {
    setShowAddUserCodeModal(false);
  };

  const addUserCode = async (data: any) => {
    let totalCount = Number(data.number) - selectedFlatUsersCount;
    
    if(totalCount > 0) {
      for(let i = 1; i <= totalCount; i++) {
        try {
          const response = await authAxios2.post(`/admin/elevators/users/add`, {
            elevator_id: elevatorId.id,
            flat_id: selectedFlat
          });
          if(response) {
            //console.log('ADD USER CODE RESPONSE ==>', response);
            if(i === Number(data.number)) {
              message.success(
              response?.data?.message != null
                ? response.data.message
                : "User code has been successfully added!"
              );
            }
          }
        } catch (e) {
          //console.log('ADD USER CODE ERROR ==>', e);
          message.error(
            e.response.data.message != null
              ? e.response.data.message
              : "User code could not be added!"
          );   
        }
      }
    } else {  
      let users = flatsUser.filter((user) => user.flat_id === selectedFlat && !user.user_id);
     
      if(users.length) {
        for(let i = 0; i < Math.abs(totalCount); i++) {    
          try {
            const response = await authAxios2.delete(`/admin/elevators/users/delete/${users[i].id}`);
            if(response) {
              //console.log('Reduce number of users ==>', response);
            }
          } catch (e) {
            console.log('Reduce number of users error ==>', e);
            message.error(
              e.response.data.message != null
                ? e.response.data.message
                : "Something went wrong. Please try again later!"
            );  
          }
        }

        message.success("User number has been successfully updated!");
      } else {
        message.error(
           "Number of Users cannot be reduced anymore."
          );   
      }
    }

    getFlats();
    setShowAddUserCodeModal(false);
    setSelectedFlat([]);
  };

  const handleOpenOnClick = () => {
    setForceOpenModal(true);
  };

  const handleOpenCancel = () => {
    setForceOpenModal(false);
  };

  const handleForceOpen = async (data) => {
    try {
      const response = await authAxios2.post(`/admin/elevator/open/${elevatorId.id}`);
      if(response) {
        setForceOpenModal(false);
      }
    } catch (e) {
      console.log('FORCE OPEN ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Opening Elevator Failed!"
      ); 
    }
  };

  const onFlatColorChange = async (colorCode, flat) => {
    setSelectedColor(colorCode);
    // try {
    //   const response = await authAxios.post(`/admin/intercoms/${deviceID.id}/color`, {
    //     color: colorCode,
    //     flatNumbers: [flat]
    //   });
    //   if(response) {
    //     getDevices();
    //     message.success("Flat Color successfully changed!");
    //   }
    // } catch (e) {
    //   console.log('Color Change Error ==>', e);
    //   message.error("Color Changed Failed. Please try again later!");
    // }
  };

  const deleteElevatorRelation = async (id) => {
    try {
      const response = await authAxios2.delete(`/admin/elevators/dependant/${id}`);
      if(response) {
        message.success("Relation successfully deleted!");
      }
    } catch (e) {
      console.log('DELETE INTERCOMS ERROR ==>', e);  
      message.error("Delete Failed. Please try again later!");
    }
  };

  const toggleElevatorConnect = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      deleteElevatorRelation(id);
    } else {
      setCheckedIDs([...checkedIDs, id]);
    }
  };

  const getElevatorssForConnect = async () => {
    try {
      const response = await authAxios2.get(`/admin/elevators/dependant/${elevatorId.id}`);
      if(response && response.data) {
        let relation = response.data.elevatorsJson.filter((item) => item.id !== Number(elevatorId.id));
        if(relation && relation.length) {
          for(let elevator of relation) {
            setCheckedIDs([...checkedIDs, elevator.id]);
          }
        }
      }
    } catch (e) {
      console.log('GET RELATED ELEVATORS ERROR ==>', e);    
    }
  };

  const handleAddElevatorOnClick = async () => {
    setShowConnectElevators(true);
    await getElevatorsList();
    await getElevatorssForConnect();
  };

  const handleAddElevatorsCancel = () => {
    setShowConnectElevators(false);
  };

  const connectElevators = async (ids) => { 
    try {
      const response = await authAxios2.post(`admin/elevators/dependant/add`, {
          elevator_id: elevatorId.id,
          dependant_id: ids
      });
      if(response) {
        //console.log('CONNECT INTERCOMS RESPONSE ==>', response.data.data);
        setShowConnectElevators(false);
        message.success("Relation successfully created!");
      }
    } catch (e) {
      console.log('CONNECT INTERCOMS ERROR ==>', e); 
      message.error("Could not add relation. Please try again later!");   
    }
  };

    const isValidPermanentCode = (code): boolean => {
      return code && code.length === 4 && /^\d+$/.test(code);
  }

  return {
    elevatorDetails: elevatorDetails,
    flats,
    flatsUser,
    showModal,
    isModalVisible,
    handleCancelEdit,
    isEditElevatorLoading,
    handleElevatorEdit,
    handleConfirm,
    handleCancel,
    handleAddFlatOnClick,
    handleAddNFCsOnClick,
    showAddFlats,
    handleAddFlatCancel,
    addFlat,
    handleNFCActivationOnClick,
    active,
    NFCUploadModal,
    handleAddNFCsCancel,
    uploadNFCFile,
    handleOnChange,
    handleAddNFCForFlatOnClick,
    addNFCModal,
    handleAddNFCModalCancel,
    handleAddNFCForFlat,
    checkedIDs,
    setSelectedFlat,
    changeCheckedValue,
    areAllChecked,
    changeSelectAll,
    handleDetailsOnClick,
    handleAddNotificationOnClick,
    setIsSortedByBalance,
    isSortedByBalance,
    showNotificationModal,
    handleAddNotificationCancel,
    addNotifications,
    isNotificationSending,
    handleFlatConfirm,
    handleAddUserCodeModal,
    showAddUserCodeModal,
    setShowAddUserCodeModal,
    handleCancelAddUserCode,
    addUserCode,
    getFlats,
    handleForceOpen,
    forceOpenModal,
    handleOpenCancel,
    handleOpenOnClick,
    elevatorId,
    isLoading,
    pinCode,
    pinCodes,
    guestPins,
    selectedGuestPinCodes,
    onPinCodeChange,
    handleDeletePinCodes,
    selectedPinCodes,
    onFlatColorChange,
    handleAddElevatorOnClick,
    showConnectElevators,
    handleAddElevatorsCancel,
    elevatorsData, 
    setElevatorsData,
    getElevatorssForConnect,
    filteredValue,
    setFilteredValue,
    connectElevators,
    toggleElevatorConnect
  };
};
