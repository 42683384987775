import { useContext, useEffect, useState } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useParams } from "react-router-dom";
import { message } from "antd";

export const useElevatorHistory = () => {
  const [isLoading, setLoading] = useState(false);
  const [elevatorId, setElevatorId] = useState(useParams);
  const [checkedIDs, setCheckedIDs] = useState<any[]>([]);
  const [hexes, setHexes] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showFlats, setShowFlats] = useState<boolean>(false);
  const [flats, setFlats] = useState([]);
  const [selectedFlat, setSelectedFlat] = useState("");
  const [filteredValue, setFilteredValue] = useState<string>("");
  const [showNFCConfirm, setShowNFCConfirm] = useState(false);
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [sortOrder, setSortOrder] = useState<string>('descend')
  const [totalPages, setTotalPages] = useState(1)
  const { authAxios, authAxios2 } = useContext(FetchContext);

  useEffect(() => {
    getKeyLogs(currentPage, pageSize, sortOrder, filteredValue);
  }, [authAxios2]);

  useEffect(() => {
    if (showFlats) {
      getFlats();
    }
  }, [showFlats]);

  useEffect(() => {
    if (filteredValue.length == 0 || filteredValue.length >= 3) {
      getKeyLogs(currentPage, pageSize, sortOrder, filteredValue);
    }
  }, [filteredValue]);

  const getFlats = async () => {
    try {
      const response = await authAxios2.get(`/admin/flats/elevators/${elevatorId.id}`);
      if (response) {
        setFlats(response.data.elevator.flats);
      }
    } catch (e) {
      console.log('GET FLATS ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Flats could not be shown. Please try again later."
      );

    }
  };

  const assignKeys = async () => {
    let data = [];

    for (let hex of hexes) {
      data.push({
        flat_id: selectedFlat,
        elevator_id: elevatorId.id,
        nfc_code: hex,
        nfc_code_enabled: true
      });
    }

    try {
      const response = await authAxios2.post(`/admin/elevators/nfc/create`, {
        add_manually: true,
        nfc_codes: data
      });
      if (response) {
        setShowFlats(false);
        getKeyLogs(currentPage, pageSize, sortOrder, filteredValue);
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "NFCs has been successfully added!"
        );
      }
    } catch (e) {
      //console.log('ADD NFC for FLAT ERROR ==>', e);
      setShowFlats(false);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "NFC could not be added!"
      );
    }
  };

  const handleAddKeysOnClick = () => {
    setShowFlats(true);
  };

  const handleAddKeysCancel = () => {
    setShowFlats(false);
  };

  const handleChange = async (pagination, filters, sorter) => {
    setSortOrder(sorter.order);
    getKeyLogs(pagination.current, pagination.pageSize, sorter.order || 'descend', filteredValue);
  };

  const getKeyLogs = async (page: number, size: number, sort: string = "descend", search: string = null) => {
    setLoading(true);

    try {
      const response = await authAxios2.get(`/admin/elevator/logs/${elevatorId.id}`, {
        params: {
          page: page,
          pagesize: size,
          sort: sort === "descend" ? "DESC" : "ASC",
          event_types: "0,5",
          search: (search.length == 0 || search.length >= 3) ? search : null
        }
      });

      if (response) {
        const newArray = [];
        for (let data of response.data.logs) {
          if (data.event_type !== "1" && data.event_type !== "2" && data.event_type !== "3" && data.event_type !== "4" && data.event_type !== "6") {        
            let newData = {
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
              id: data.id,
              flat_id: data.flat_id,
              device_id: data.device_id,
              address: data.address,
              code: data.value,
              event_type: data.event_type,
              event_time: data.event_time
            };
            newArray.push(newData);
          }
        }
        setKeys(newArray);
        setTotalPages(response.data.pagination.totalPages);
        setCurrentPage(response.data.pagination.currentPage);

        setPageSize(response.data.pagination.pageSize);
        setLoading(false);
      }
    } catch (e) {
      console.log('GET KEYS ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Keys could not be shown. Please refresh."
      );
    }
  };

  const changeCheckedValue = (row, id) => {
    if (checkedIDs.includes(id)) {
      setCheckedIDs(checkedIDs.filter((it) => it != id));
      setHexes(hexes.filter((it) => it != row.code));
    } else {
      setCheckedIDs([...checkedIDs, id]);
      setHexes([...hexes, row.code]);
    }
  };

  const changeSelectAll = () => {
    if (checkedIDs.length !== 0) {
      setCheckedIDs([]);
      setHexes([]);
      setAreAllChecked(false);
    } else {
      setAreAllChecked(true);
      setCheckedIDs(keys.map((it) => it.id));
      setHexes(keys.map((it) => it.code))
    }
  };

  const changeCheckedFlats = (row, id) => {
    if (selectedFlat === id) {
      setSelectedFlat("");
    } else {
      setSelectedFlat(id);
    }
  };

  const handleDeleteNFCCode = async () => {
    try {
      const response = await authAxios2.post(`/admin/keys/delete`, {
        keys: checkedIDs
      });
      if (response) {
        //console.log('DELETE KEY ==>', response);
        getKeyLogs(currentPage, pageSize, sortOrder, filteredValue);
        setCheckedIDs([]);
        setHexes([]);
        message.success(
          response?.data?.message != null
            ? response.data.message
            : "Key has been successfully deleted!"
        );
      }
    } catch (e) {
      console.log('DELETE KEY ERROR ==>', e);
      message.error(
        e.response.data.message != null
          ? e.response.data.message
          : "Keys could not be deleted!"
      );
    }
  };

  const handleCancelNFCCodeDelete = () => {
    setShowNFCConfirm(false);
  };

  return {
    changeCheckedValue,
    checkedIDs,
    keys,
    isLoading,
    showFlats,
    handleAddKeysOnClick,
    handleAddKeysCancel,
    assignKeys,
    flats,
    selectedFlat,
    changeCheckedFlats,
    setFilteredValue,
    filteredValue,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete,
    areAllChecked,
    changeSelectAll,
    handleChange,
    currentPage,
    pageSize,
    totalPages
  };
};
