import React from "react";
import { Avatar, Space, Button } from "antd";
import { useCurrentUser } from "./useCurrentUser";
import { Menu, Dropdown, Modal } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export const CurrentUser = (props: any) => {
  const {
    showConfirm,
    handleCancel,
    handleConfirm,
    handleShowConfirm,
  } = useCurrentUser();
  const {t} = useTranslation()

  const menu = (
    <Menu>
      <Menu.Item>
        <a href={"/profile"}>
          <UserOutlined /> {t('UserProfile')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <Button onClick={handleShowConfirm}>
          {" "}
          <LogoutOutlined /> {t('Logout')}{" "}
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      {showConfirm && (
        <>
          <Modal
            visible={showConfirm}
            onOk={handleConfirm}
            onCancel={handleCancel}
          >
            <p>{t('ConfirmLogout')}</p>
          </Modal>
        </>
      )}
      <Space direction="vertical">
        <Space>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Avatar
              style={{ backgroundColor: "#87d068" }}
              icon={<UserOutlined />}
            />
          </Dropdown>
        </Space>
      </Space>
    </div>
  );
};
