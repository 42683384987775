import React, {useContext} from "react";
import {useFlatDetails} from "./useFlatDetails";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Space,
  Table,
  Switch,
  Select,
} from "antd";
import {
  BulbTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  SendOutlined
} from "@ant-design/icons";
import { format } from "date-fns";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../context/AuthContext";

export const FlatDetails = () => {
  const {
    flatDetails,
    users,
    payments,
    handleFlatActivationOnClick,
    handleUserActivationOnClick,
    handleDeleteUserFromFlat,
    handleCancelDelete,
    showEditModal,
    handleEditBalanceModal,
    handleCancelEdit,
    setBalance,
    editBalance,
    setPhoneNumber,
    setIntercomCode,
    handleAddUserModal,
    handleCancelAddUser,
    addUser,
    showAddUserModal,
    intercomCode,
    addComment,
    comments,
    form,
    handleEditResidentsModal,
    setResidents,
    editResidents,
    residents,
    handleDeleteCommentFromFlat,
    handleDeleteNFCCode,
    handleCancelNFCCodeDelete,
    handleNFCActivationOnClick,
    NFCs,
    addKeys,
    handleAddKeysCancel,
    handleAddKeysOnClick,
    showAddKeys,
    transactions,
    packagesFromIntercom,
    selectedPackage,
    onPackageChange
  } = useFlatDetails();

  const auth = useContext(AuthContext);
  const { confirm } = Modal;
  const {t} = useTranslation()


  const handleShowConfirm = (user: any) => {
    confirm({
      title: "Delete User",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete this user?",
      onOk() {
        handleDeleteUserFromFlat(user);
      },
      onCancel() {
        handleCancelDelete();
      },
    });
  };

  const handleNFCShowConfirm = (id: any) => {
    confirm({
      title: "Delete NFC Code",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete this NFC Code?",
      onOk() {
        handleDeleteNFCCode(id);
      },
      onCancel() {
        handleCancelNFCCodeDelete();
      },
    });
  };

  const handleCommentShowConfirm = (id: any) => {
    confirm({
      title: "Delete Comment",
      icon: <ExclamationCircleOutlined />,
      content: "Are You sure You want to delete this comment?",
      onOk() {
        handleDeleteCommentFromFlat(id);
      },
      onCancel() {
        handleCancelDelete();
      },
    });
  };

  const flatColumns = [
    {
      title: `${t("Flat")} #`,
      dataIndex: "flatNumber",
      key: "flatNumber ",
      render: (flatNumber) => {
        return <div>{`${flatNumber}`}</div>;
      },
    },
    {
      title: t("Address"),
      key: "address",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return "Not Activated";
        }
        return <div>{`${address}`}</div>;
      },
    },
    {
      title: `${t("NumberOfResidents")}`,
      dataIndex: "residentCount",
      key: "residentCount",
      render: (residentCount) => {
        return (
        <div
          onDoubleClick={() => handleEditResidentsModal()}
        >
          {`${residentCount}`}
        </div>
        );
      },
    },
    {
      title: t("Package"),
      dataIndex: "activePackage",
      key: "packageName ",
      render: (activePackage) => {
        return <div>{`${activePackage?.name}`}</div>;
      },
    },
    {
      title: t("ValidUntil"),
      dataIndex: "activeUntil",
      key: "activeUntil",
      render: (activeUntil) => {
        if (!activeUntil) {
          return "NONE";
        }
        return <div>{activeUntil}</div>;
      },
    },
    {
      title: t("Balance"),
      dataIndex: "balance",
      key: "balance",
      render: (balance) => {
        return (
          <div
            // onDoubleClick={() => handleEditBalanceModal()}
          >{`${balance} AMD`}</div>
        );
      },
    },
    {
      title: t("Active"),
      key: "active",
      dataIndex: "active",
      render: (active) => {
        return (
          <Switch
            checked={active}
            onClick={() => handleFlatActivationOnClick(active)}
          />
        );
      },
    },
  ];

  const flatColumnsSuperAdmin = () => {
    let tempPackage = flatDetails[0]?.nextPackage || flatDetails[0]?.activePackage;

    return [
      {
        title: `${t("Flat")} #`,
        dataIndex: "flatNumber",
        key: "flatNumber ",
        render: (flatNumber) => {
          return <div>{`${flatNumber}`}</div>;
        },
      },
      {
        title: t("Address"),
        key: "address",
        dataIndex: "address",
        render: (address) => {
          if (!address) {
            return "Not Activated";
          }
          return <div>{`${address}`}</div>;
        },
      },
      {
        title: `${t("NumberOfResidents")}`,
        dataIndex: "residentCount",
        key: "residentCount",
        render: (residentCount) => {
          return (
              <div
                  onDoubleClick={() => handleEditResidentsModal()}
              >
                {`${residentCount}`}
              </div>
          );
        },
      },
      {
        title: t("Package"),
        dataIndex: "package",
        key: "packageName",
        render: () => {
          return <div>
            <Select
                style={{width: 120, marginRight: 16}}
                value={selectedPackage !== null ? selectedPackage.name : "NONE"}
                onChange={(item) => onPackageChange(item)}
                options={tempPackage
                    ? [{title: "NONE", value: "NONE"}, ...packagesFromIntercom.map(pkg => ({title: pkg.name, value: pkg.name}))]
                    : [...packagesFromIntercom.map(pkg => ({title: pkg.name, value: pkg.name}))]}
                dropdownMatchSelectWidth={false}
            />
          </div>
        }
      },
      {
        title: t("ValidUntil"),
        dataIndex: "activeUntil",
        key: "activeUntil",
        render: (activeUntil) => {
          if (!activeUntil) {
            return "NONE";
          }
          return <div>{activeUntil}</div>;
        },
      },
      {
        title: t("Balance"),
        dataIndex: "balance",
        key: "balance",
        render: (balance) => {
          return (
              <div
                  // onDoubleClick={() => handleEditBalanceModal()}
              >{`${balance} AMD`}</div>
          );
        },
      },
      {
        title: t("Active"),
        key: "active",
        dataIndex: "active",
        render: (active) => {
          return (
              <Switch
                  checked={active}
                  onClick={() => handleFlatActivationOnClick(active)}
              />
          );
        },
      },
    ];
  };

  const usersColumns = [
    {
      title: t("Name"),
      dataIndex: "user",
      key: "name",
      render: (user) => {
        if (!user) {
          return "NONE";
        }
        return <div>{`${user.name} ${user.surname ? user.surname : ""} ${user.isAdmin ? "(Admin)" : ""}`}</div>;
      },
    },
    {
      title: t("PhoneNumber"),
      dataIndex: "user",
      key: "phone",
      render: (user) => {
        if (!user) {
          return "NONE";
        }
        return <div>{`${user.phoneNumber}`}</div>;
      },
    },
    {
      title: t("Email"),
      dataIndex: "user",
      key: "email",
      render: (user) => {
        if (!user) {
          return "NONE";
        }
        return <div>{`${user.email}`}</div>;
      },
    },
    {
      title: t("Code"),
      dataIndex: "code",
      key: "code",
      render: (code) => {
        if (!code) {
          return "NONE";
        }
        return (
          <div onDoubleClick={() => handleAddUserModal(code)}>{`${code}`}</div>
        );
      },
    },
    {
      title: t("Admin"),
      key: "admin",
      dataIndex: "user",
      render: (user) => {
        if (user) {
          return (
            <BulbTwoTone
              twoToneColor={user.isAdmin === "true" ? "green" : "red"}
              style={{ fontSize: "20px" }}
            />
          );
        }
        return (
          <BulbTwoTone twoToneColor={"red"} style={{ fontSize: "20px" }} />
        );
      },
    },
    {
      title: t("Active"),
      key: "active",
      dataIndex: "user",
      render: (user) => {
        return (
          <Switch
            disabled={!user}
            checked={!user ? true : user.isActive}
            onClick={() => handleUserActivationOnClick(user)}
          />
        );
      },
    },
    {
      title: t("Delete"),
      key: "id",
      dataIndex: "user",
      render: (user) => {
        return (
          <Space size="middle">
            <Button
              danger
              disabled={!user}
              onClick={() => handleShowConfirm(user)}
            >
              {/* {" "}
              {t("Delete")} */}
              <DeleteTwoTone twoToneColor={!user ? "grey" : "red"} />
            </Button>
          </Space>
        );
      },
    },
  ];

  const paymentColumns = [
    {
      title: t("PaymentID"),
      dataIndex: "id",
      key: "id",
      render: (id, row) => {
        if(row.title && row.title === "CASH") {
          return <div>{`${row.title}`}</div>;
        } else if(row.amount === 1100) {
          return <div>{`${t('boon_subscription')}`}</div>;
        } else {
          return <div>{`${id}`}</div>
        }
      },
    },
    {
      title: t("Date"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => {
        return <div>{format(new Date(date), "dd-MMM-yy H:m:s")}</div>;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <div>{`${amount} AMD`}</div>;
      },
    },
    {
      title: t("OrderID"),
      dataIndex: "orderId",
      key: "orderId",
      render: (orderId) => {
        if (!orderId) {
          return "NONE";
        }
        return <div>{`${orderId}`}</div>;
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (!status) {
          return "NONE";
        }
        return <div>{`${status}`}</div>;
      },
    },
    {
      title: t("User"),
      dataIndex: "userName",
      key: "userName",
      render: (userName) => {
        if (!userName) {
          return "NONE";
        }
        return <div>{`${userName}`}</div>;
      },
    },
  ];

  const transactionColumns = [
    {
      title: t("TransactionID"),
      dataIndex: "id",
      key: "id",
      render: (id, row) => {
        if(row.description) {
          return <div>{`${row.description}`}</div>;
        } else if(row.amount === 1100) {
          return <div>{`${t('boon_subscription')}`}</div>;
        } else {
          return <div>{`${id}`}</div>
        }
      },
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Date"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => {
        return <div>{format(new Date(date), "dd-MMM-yy H:m:s")}</div>;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return <div>{`${amount} AMD`}</div>;
      },
    },
    {
      title: t("User"),
      dataIndex: "name",
      key: "name",
      render: (name) => {
        return <div>{`${name}`}</div>;
      },
    },
  ];


  const nfcColumns = [
    {
      title: t("NFCCodes"),
      dataIndex: "key",
      key: "key",
    },
    {
      title: t("NFCCodesEnabled"),
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled, row) => {
        return (
          <Switch
            checked={enabled}
            onClick={() => handleNFCActivationOnClick(row)}
          />
        );
      },
    },
    {
      title: t("Delete"),
      key: "id",
      dataIndex: "id",
      render: (id, row) => {
        return (
          <Space size="middle">
            <Button
              danger
              onClick={() => handleNFCShowConfirm(id)}
            >
              <DeleteTwoTone twoToneColor={!id ? "grey" : "red"} />
            </Button>
          </Space>
        );
      },
    },
  ];

  const commentsColumns = [
    {
      title: t("Comments"),
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: t("CreatedDate"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => {
        return <div>{format(new Date(date), "dd-MMM-yy H:m:s")}</div>;
      },
    },
    {
      title: t("Delete"),
      key: "commentId",
      dataIndex: "commentId",
      render: (commentId) => {
        return (
          <Space size="middle">
            <Button
              danger
              onClick={() => handleCommentShowConfirm(commentId)}
            >
              {/* {" "}
              {t("Delete")} */}
              <DeleteTwoTone twoToneColor={"red"} />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Divider orientation={"left"}> {t("FlatDetails")} </Divider>
      {
        (auth.isSuperAdmin) ?
        <Table
          columns={flatColumnsSuperAdmin()}
          dataSource={flatDetails}
          pagination={false}
        /> :
        <Table
            columns={flatColumns}
            dataSource={flatDetails}
            pagination={false}
        />
      }
      <Divider orientation={"left"}> {t("Users")} </Divider>
      <Table columns={usersColumns} dataSource={users} />
      <Divider orientation={"left"}>{t("Payments")}</Divider>
      <Table columns={paymentColumns} dataSource={payments} />
      <Divider orientation={"left"}>{t("Transactions")}</Divider>
      <Table columns={transactionColumns} dataSource={transactions} />
      <Divider orientation={"left"}>{t("NFCCodes")}</Divider>
      <Button onClick={handleAddKeysOnClick} style={{ marginRight: 8, marginBottom: 16 }}>
        {" "}{t("AddNFCCodes")}{" "}
      </Button>
      <Table columns={nfcColumns} dataSource={NFCs} />
      <Divider orientation={"left"}>{t("ForComments")}</Divider>
      <Form form={form} onFinish={addComment} style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
        <Form.Item
            label={t("LeaveComment")}
            name="body"
            preserve
          >
            <Input.TextArea style={{height: '15em'}}/>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SendOutlined />}
            style={{width: '20%', alignSelf: 'end'}}
          >
              {t("Add")}
          </Button>
      </Form>
      <Divider/>
      <Table columns={commentsColumns} dataSource={comments} />
      <Modal
        title={t("EditBalance")}
        open={showEditModal}
        onCancel={handleCancelEdit}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => editBalance()}>
          <Form.Item
            label={t("Balance")}
            name="balance"
            // initialValue={slideName}
            preserve
          >
            <Input type="text" onChange={(e) => setBalance(e.target.value)} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("EditNumberOfResidents")}
        open={showEditModal}
        onCancel={handleCancelEdit}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => editResidents()}>
          <Form.Item
            label={t("NumberOfResidents")}
            name="residentCount"
            initialValue={residents}
            preserve
          >
            <Input type="text" onChange={(e) => setResidents(e.target.value)} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("AddUser")}
        open={showAddUserModal}
        onCancel={handleCancelAddUser}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={() => addUser()}>
          <Form.Item 
            label={t("FlatCode")}
            name="code"
            preserve
          >
            <Input
              defaultValue={intercomCode}
              type="text"
              onChange={(e) => setIntercomCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={t("PhoneNumber")}
            name="number"
            // initialValue={slideName}
            preserve
          >
            <Input
              type="text"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {t("AddUser")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("AddKeys")}
        open={showAddKeys}
        onOk={form.submit}
        onCancel={handleAddKeysCancel}
        style={{ pointerEvents: "none" }}
        footer={null}
      >
        <Form form={form} onFinish={addKeys}>
          <Form.Item
            label={t("AddKey")}
            name="hex"
            //rules={[{ required: true, message: "Please input hex code!" }]}
            preserve
          >
            <Input type={"text"} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
              {t("Save")}
          </Button>
        </Form>
      </Modal>

    </div>
  );
};
